import * as React from 'react';

import { Col, Row, ListGroup } from 'react-bootstrap';

import SubsidiaryListButtons from './SubsidiaryListButtons';

import { getClientLocationSend } from '../../util/exportUtil';

import {
  SubsidiaryListItemProps,
  SubsidiaryListItemState,
} from '../../@types/Subsidiary.d';

/**
 * Component to display a subsidiary as list item.
 */
export default class SubsidiaryListItem extends React.Component<
  SubsidiaryListItemProps,
  SubsidiaryListItemState
> {
  constructor(props: SubsidiaryListItemProps) {
    super(props);

    this.state = {
      showColorPicker: false,
    };

    this.onClickItem = this.onClickItem.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
    this.changeSubsidiaryColor = this.changeSubsidiaryColor.bind(this);
  }

  /**
   * Click action to select a subsidiary as new selected subsidiary.
   *
   * @param event
   */
  onClickItem(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    const { planningRestriction } = this.props;

    event.stopPropagation();

    if (planningRestriction === 'TEMPLATE') return;

    const { subsidiary, setSubsidiarySelected } = this.props;
    setSubsidiarySelected(subsidiary);
  }

  /**
   * Method to show the color picker.
   */
  showColorPicker(): void {
    const { showColorPicker } = this.state;
    this.setState({ showColorPicker: !showColorPicker });
  }

  /**
   * Method to set a new subsidiary color.
   *
   * @param color
   */
  changeSubsidiaryColor(color: string): void {
    const { subsidiary, changeSubsidiaryColor } = this.props;

    subsidiary.colorSelectedFill = color;
    changeSubsidiaryColor(getClientLocationSend(subsidiary));
  }

  render(): JSX.Element {
    const {
      planningRestriction,
      subsidiary,
      showNewSubsidiaryDialog,
      deleteSubsidiary,
      zoomToSubsidiary,
      showSubsidiaryDistributionTemplates,
      showSubsidiaryDistributionTemplateModal,
    } = this.props;
    const { showColorPicker } = this.state;

    return (
      <ListGroup.Item
        onClick={this.onClickItem}
        className="selectable hover-item"
        active={subsidiary.selected}
      >
        <Row className="no-gutters">
          <Col md={12}>{subsidiary.name}</Col>
          <Col md={12}>
            <SubsidiaryListButtons
              planningRestriction={planningRestriction}
              subsidiary={subsidiary}
              showColorPicker={showColorPicker}
              showPicker={this.showColorPicker}
              showNewSubsidiaryDialog={showNewSubsidiaryDialog}
              deleteSubsidiary={deleteSubsidiary}
              changeSubsidiaryColor={this.changeSubsidiaryColor}
              zoomToSubsidiary={zoomToSubsidiary}
              showSubsidiaryDistributionTemplates={
                showSubsidiaryDistributionTemplates
              }
              showSubsidiaryDistributionTemplateModal={
                showSubsidiaryDistributionTemplateModal
              }
            />
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}
