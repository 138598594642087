import './styles/App.scss';
import './styles/AreaList.scss';

import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { cloneDeep, remove, forEach } from 'lodash';
import { Feature } from 'ol';

import LocalitiesModal from './components/modal/LocalitiesModal';
import SubsidiaryListContainer from './components/subsidiaryList/SubsidiaryListContainer';
import SubsidiaryAreaList from './components/areaList/SubsidiaryAreaList';
import MapComponent from './components/map/MapComponent';
import AreaList from './components/areaList/AreaList';
import ImportModal from './components/modal/ImportModal';
import MapMenu from './components/map/MapButtons';
import LoadingOverlay from './components/common/CustomLoadingOverlay';
import ClientLocationModal from './components/modal/ClientLocationModal/ClientLocationModal';
import CirculationItem from './components/common/CirculationItem';
import DistributionTemplateListContainer from './components/distributionTemplateList/DistributionTemplateListContainer';
import DistributionTemplateModal from './components/modal/DistributionTemplateModal';
import ConfirmationModal from './components/modal/ConfirmationModal';
import ResponseModal from './components/modal/ResponseModal';
import HistoryContainer from './components/historyList/HistoryListContainer';
import SubsidiaryDistributionTemplateModal from './components/modal/SubsidiaryDistributionTemplateModal/SubsidiaryDistributionTemplateModal';
import PriceItem from './components/common/PriceItem';
import IsochroneModal from './components/modal/IsochroneModal';
import AreaListHeader from './components/areaList/AreaListHeader';
import WarningMessage from './components/common/WarningMessage';

import {
  getClientData,
  getAreaData,
  updateClientLocation,
  createClientLocation,
  deleteClientLocation,
  deleteDistributionTemplate,
  createDistributionTemplate,
  createSubsidiaryDistributionTemplate,
  deleteSubsidiaryDistributionTemplate,
  getTotalPrice,
  getHistoryData,
  getHistoryItemData,
  getIsochrone,
  getClientAreaMetaData,
  updateDistributionTemplate,
  getClientLocations,
  getClientDistributionTemplates,
} from './util/api';
import {
  LOADING_PLEASE_WAIT,
  LOADING_PROCESS_REQUEST,
  CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE,
  CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE,
  CONFIRMATION_MODAL_TITLE_DELETE_SUBSIDIARY,
  CONFIRMATION_MODAL_CONTENT_DELETE_SUBSIDIARY,
  RESPONSE_MODAL_FAILURE_TITLE,
  RESPONSE_MODAL_FAILURE_CONTENT,
  CONFIRMATION_MODAL_TITLE_REMOVE_ALL_AREAS,
  CONFIRMATION_MODAL_CONTENT_REMOVE_ALL_AREAS,
  WARNING_MESSAGE_TITLE_NO_SUBSIDIARY_SELECTED,
  WARNING_MESSAGE_CONTENT_NO_SUBSIDIARY_SELECTED,
  WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
  WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
  CONFIRMATION_MODAL_TITLE_OVERWRITE_DISTRIBUTION_TEMPLATE,
  CONFIRMATION_MODAL_CONTENT_OVERWRITE_DISTRIBUTION_TEMPLATE,
} from './constants/labels';
import {
  WEEKPART_WEEKEND,
  WEEKPART_MIDWEEK,
  FEATURE_FIELD_SUBSIDIARY_NAME,
  FEATURE_FIELD_SUBSIDIARY_STREET,
  FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER,
  FEATURE_FIELD_SUBSIDIARY_POSTCODE,
  FEATURE_FIELD_SUBSIDIARY_CITY,
  TRANSMISSION_TYPE_ORDER,
  REQUEST_IDENTIFIER_GET_CLIENT_DATA,
  REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS,
  REQUEST_IDENTIFIER_DELETE_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_GET_AREA_DATA,
  REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION,
  REQUEST_IDENTIFIER_CREATE_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION,
  REQUEST_IDENTIFIER_CREATE_SUBSIDIARY_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_DELETE_SUBSIDIARY_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_GET_CLIENT_HISTORY,
  REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM,
  REQUEST_IDENTIFIER_GET_ISOCHRONE,
  REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE,
  WEEKPART_ARRAY,
  DUMMY_DYN_PARAMS,
  REQUEST_IDENTIFIER_GET_CLIENT_META,
  LAYER_TYPE_POSTCODE,
  COUNTRY_CODE_DE,
  WarningMessageType,
  REQUEST_IDENTIFIER_PRINT_MAP,
  REQUEST_IDENTIFIER_UPDATE_DISTRIBUTION_TEMPLATE,
  REQUEST_IDENTIFIER_GET_DISTRIBUTION_TEMPLATES,
} from './constants/constants';
import { sortClientLocations, sortHistoryTemplates } from './util/sortUtil';
import {
  exportAreaCSV,
  exportSubsidiaryCSV,
  exportExcel,
  getSubsidiariesSend,
  getAreasSend,
} from './util/exportUtil';
import {
  extractOrderTemplate,
  extractOfferTemplate,
} from './util/responseUtil/clientResponseUtil';
import {
  addAreaStub,
  addAreaStubs,
  getAreaStubsFromTemplate,
  getAdditionalAreasDifference,
  getAreaDataFormat,
  removeNotSerializableFromAreas,
  removeNotSerializableFromSubsidiaries,
  removeNotSerializableFromSubsidiary,
  calculateAreaCirculation,
  getAllAdditionalAreas,
  getDistributionTemplate,
  getSubsidiaryDistributionTemplate,
  getAllAreaFeatures,
  calculateAreaCirculationTotal,
  calculateSubsidiaryCalculation,
  getAreaDescriptions,
  changeAreasLayerType,
} from './util/areaUtil';
import config from './config';
import { generateLocationStyle } from './util/featureStyle';

import { AppProps, AppState } from './@types/App.d';
import {
  Client,
  Coordinates,
  ClientLocation,
  Weekpart,
  DistributionTemplate,
  DistributionTemplateLocation,
  Product,
  SubsidiaryDistributionTemplate,
  OrderHistoryTemplate,
  OfferHistoryTemplate,
  OSRProfiles,
  DynamicPlaningParam,
  ClientMetaData,
  PriceResult,
  PaperSize,
  HistoryTemplate,
  TotalPrice,
  LocationPrice,
  ClientLocationSend,
  LayerType,
} from './@types/Common.d';
import {
  MessagePayload,
  MessageType,
  Message,
  AreaMessagePayload,
  HideSubsidiarySelectionPayload,
  LocalityMessagePayload,
  MapInfoPayload,
  InitAreasPayload,
} from './@types/MessageTypes.d';
import { Area, SubsidiarySendFormat, AreaDescription } from './@types/Area.d';
import { Address } from './@types/Modal.d';
import PrintMapModal from './components/modal/PrintModal';
import MapContainer from './components/map/MapContainer';

/**
 * The main component that holds the application and controls the
 * data flow.
 */
class App extends React.Component<AppProps, AppState> {
  /**
   * Reference to the mapcomponent
   */
  private mapComponentRef = React.createRef<MapComponent>();

  /**
   * Reference to the mapcotainer
   */
  private mapContainerRef = React.createRef<MapContainer>();

  /**
   * Reference to the fillscreen container
   */
  private fullScreenContainerRef = React.createRef<any>();

  /**
   * Reference to the localities modal
   */
  private localitiesModalRef = React.createRef<LocalitiesModal>();

  /**
   * Reference to the client location modal
   */
  private clientLocationModalRef = React.createRef<ClientLocationModal>();

  /**
   * Reference to the distribution template modal
   */
  private templateModalRef = React.createRef<DistributionTemplateModal>();

  /**
   * Reference to the subsidiary distribution template modal
   */
  private subsidiaryDistributionTemplateModalRef = React.createRef<
    SubsidiaryDistributionTemplateModal
  >();

  /**
   * Reference to the subsidiary distribution template modal
   */
  private warningMessageRef = React.createRef<WarningMessage>();

  constructor(props: AppProps) {
    super(props);

    const {
      initWeekpart,
      initDistributionWeek,
      initDistributionYear,
    } = this.props;

    this.state = {
      weekpart: initWeekpart,
      distributionWeek: initDistributionWeek,
      distributionYear: initDistributionYear,
      totalCirculation: 0,

      isFullscreen: false,
      isLoading: false,
      pendingRequests: [],
      lockSelection: false,
      applyLayerTypeToAll: false,

      showImportModal: false,
      showLocalitiesModal: false,
      showSubsidiaryList: false,
      showDistributionTemplateList: false,
      showHistoryList: false,
      showSubsidiaryModal: false,
      showDistributionTemplateModal: false,
      showConfirmationModal: false,
      showResponseModal: false,
      showSubsidiaryDistributionTemplateModal: false,
      showIsochroneModal: false,
      showPrintMapModal: false,
      showAllAreaItems: true,
      showWarningMessage: false,
      showRevertPerimeterButton: false,

      areas: [] as Area[],
      selectedSubsidiaries: [] as ClientLocation[],
    };

    this.getAllAreasFromSubsidiaries = this.getAllAreasFromSubsidiaries.bind(
      this
    );
    this.addArea = this.addArea.bind(this);
    this.addAreas = this.addAreas.bind(this);
    this.addRemoveAreaRestrictedPlanning = this.addRemoveAreaRestrictedPlanning.bind(
      this
    );
    this.addPerimeterAreas = this.addPerimeterAreas.bind(this);
    this.getDynamicAreas = this.getDynamicAreas.bind(this);
    this.addInitialAreas = this.addInitialAreas.bind(this);
    this.addHistory = this.addHistory.bind(this);
    this.removeArea = this.removeArea.bind(this);
    this.removeAreas = this.removeAreas.bind(this);
    this.removeAllAreas = this.removeAllAreas.bind(this);
    this.removeAllAreasCallback = this.removeAllAreasCallback.bind(this);
    this.revertPerimeter = this.revertPerimeter.bind(this);
    this.removeSubsidiaryFromSelection = this.removeSubsidiaryFromSelection.bind(
      this
    );
    this.processNewAreas = this.processNewAreas.bind(this);
    this.updateAreaSelection = this.updateAreaSelection.bind(this);
    this.templateModalCallback = this.templateModalCallback.bind(this);
    this.drawPerimeter = this.drawPerimeter.bind(this);
    this.convertAreaLayerType = this.convertAreaLayerType.bind(this);

    this.getClientAreaMetaData = this.getClientAreaMetaData.bind(this);
    this.getClientHistory = this.getClientHistory.bind(this);
    this.getClientLocations = this.getClientLocations.bind(this);
    this.getClientDistributionTemplates = this.getClientDistributionTemplates.bind(
      this
    );

    this.isSelectedByCurrentSubsidiary = this.isSelectedByCurrentSubsidiary.bind(
      this
    );
    this.getMultiSelectionSubsidiaries = this.getMultiSelectionSubsidiaries.bind(
      this
    );
    this.getConflictingAreas = this.getConflictingAreas.bind(this);

    this.onMessageReceive = this.onMessageReceive.bind(this);
    this.initMessage = this.initMessage.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.updateSubsidiary = this.updateSubsidiary.bind(this);
    this.createSubsidiary = this.createSubsidiary.bind(this);
    this.deleteSubsidiary = this.deleteSubsidiary.bind(this);

    this.removeSubsidiaryDistributionTemplate = this.removeSubsidiaryDistributionTemplate.bind(
      this
    );
    this.applySubsidiaryDistributionTemplate = this.applySubsidiaryDistributionTemplate.bind(
      this
    );
    this.applyInitAreas = this.applyInitAreas.bind(this);
    this.createSubsidiaryDistributionTemplate = this.createSubsidiaryDistributionTemplate.bind(
      this
    );

    this.changeFullscreen = this.changeFullscreen.bind(this);
    this.enableLoadingOverlay = this.enableLoadingOverlay.bind(this);
    this.changeApplyLayerTypeToAll = this.changeApplyLayerTypeToAll.bind(this);

    this.showImportModal = this.showImportModal.bind(this);
    this.showLocalitiesModal = this.showLocalitiesModal.bind(this);
    this.showSubsidiaryModal = this.showSubsidiaryModal.bind(this);
    this.showDistributionTemplateModal = this.showDistributionTemplateModal.bind(
      this
    );
    this.showHistoryList = this.showHistoryList.bind(this);
    this.showResponseModal = this.showResponseModal.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.showSubsidiaryDistributionTemplates = this.showSubsidiaryDistributionTemplates.bind(
      this
    );
    this.showIsochroneModal = this.showIsochroneModal.bind(this);
    this.showAllAreaItems = this.showAllAreaItems.bind(this);
    this.showWarningMessage = this.showWarningMessage.bind(this);
    this.showRevertPerimeter = this.showRevertPerimeter.bind(this);
    this.showPrintMapModal = this.showPrintMapModal.bind(this);
    this.showLayerTypeSelection = this.showLayerTypeSelection.bind(this);

    this.centerLocation = this.centerLocation.bind(this);
    this.fitSelection = this.fitSelection.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.showSubsidiaryList = this.showSubsidiaryList.bind(this);
    this.showDistributionTemplateList = this.showDistributionTemplateList.bind(
      this
    );
    this.toggleSubsidiaryList = this.toggleSubsidiaryList.bind(this);
    this.toggleDistributionTemplateList = this.toggleDistributionTemplateList.bind(
      this
    );
    this.toggleHistoryList = this.toggleHistoryList.bind(this);
    this.toggleWeekpart = this.toggleWeekpart.bind(this);
    this.setWeekpart = this.setWeekpart.bind(this);
    this.zoomToSubsidiary = this.zoomToSubsidiary.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.printSelection = this.printSelection.bind(this);
    this.zoomToAddress = this.zoomToAddress.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.applyHistoryTemplate = this.applyHistoryTemplate.bind(this);
    this.applyTemplate = this.applyTemplate.bind(this);
    this.getIsochrone = this.getIsochrone.bind(this);

    this.setSubsidiarySelected = this.setSubsidiarySelected.bind(this);
    this.addSubsidiaryToSelection = this.addSubsidiaryToSelection.bind(this);
    this.removeSubsidiaryFromSelection = this.removeSubsidiaryFromSelection.bind(
      this
    );

    this.applyDistributionTemplate = this.applyDistributionTemplate.bind(this);
    this.removeDistributionTemplate = this.removeDistributionTemplate.bind(
      this
    );
    this.createDistributionTemplate = this.createDistributionTemplate.bind(
      this
    );
    this.updateDistributionTemplate = this.updateDistributionTemplate.bind(
      this
    );
    this.updateDistributionTemplateCallback = this.updateDistributionTemplateCallback.bind(
      this
    );

    this.setLocalitiesSelection = this.setLocalitiesSelection.bind(this);
    this.findArea = this.findArea.bind(this);
    this.hideSubsidiarySelection = this.hideSubsidiarySelection.bind(this);
  }

  /**
   * Used to initalize the application
   */
  async componentDidMount(): Promise<void> {
    const { clientUUID } = this.props;

    // Add an eventlistener to communicate with an overlaying portal (e.g. FPP)
    window.addEventListener('message', this.onMessageReceive);

    // If a client id is provided continue in subsidiary mode
    if (clientUUID) {
      this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
      // Get the corresponding client
      const client = (await getClientData(clientUUID)) as Client;

      // If a valid client is revceived continue in subsidary mode
      if (client) {
        this.setState({
          client,
          lockSelection: client.planningRestriction === 'TEMPLATE',
        });

        // Get additional client data
        this.getClientHistory();
        this.getClientAreaMetaData();
        this.getClientLocations();
        this.getClientDistributionTemplates();
      } else {
        this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_DATA);
      }
    }
  }

  /**
   * Receive messages from the overlaying portal (e.g. FPP)
   */
  onMessageReceive(event: MessageEvent): void {
    let { data } = event;

    if ((data as Message).type) {
      data = data as Message;
      const { type } = data;

      if (type === MessageType.MESSAGE_TYPE_INIT_AREAS) {
        if (data.payload) this.applyInitAreas(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_ADD_SUBSIDIARY) {
        // TODO
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_SUBSIDIARY) {
        if (data.payload) this.removeSubsidiaryFromSelection(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_AREA) {
        if (data.payload) this.removeArea(this.findArea(data.payload));
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_AREAS) {
        // TODO
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES) {
        if ((data.payload as Area).areaKey)
          this.showLocalitiesModal(this.findArea(data.payload), true);
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES_EXTERNAL) {
        if ((data.payload as Area).areaKey)
          this.setLocalitiesSelection(data.payload as Area);
      } else if (type === MessageType.MESSAGE_TYPE_CHANGE_WEEKPART) {
        if (
          data.payload === WEEKPART_MIDWEEK ||
          data.payload === WEEKPART_WEEKEND
        ) {
          this.setWeekpart(data.payload);
        }
      } else if (type === MessageType.MESSAGE_TYPE_ADD_ORDER) {
        if (data.payload) this.addHistory(data.payload);
      } else if (type === MessageType.MESSAGE_TYPE_CHANGE_PRODUCT) {
        if (data.payload) this.setSelectedProduct(data.payload as Product);
      } else if (type === MessageType.MESSAGE_TYPE_REMOVE_ALL_AREAS)
        this.removeAllAreas();
      else if (type === MessageType.MESSAGE_TYPE_HIDE_SUBSIDIARY_SELECTION) {
        this.hideSubsidiarySelection(
          (data.payload as HideSubsidiarySelectionPayload).subsidiaryId,
          (data.payload as HideSubsidiarySelectionPayload).show
        );
      } else if (type === MessageType.MESSAGE_TYPE_UPDATE_PRICE) {
        this.setState({
          price: data.payload as TotalPrice,
        });
      } else if (type === MessageType.MESSAGE_TYPE_MAP_INFO) {
        this.sendMessage(this.getMapInfo(), MessageType.MESSAGE_TYPE_MAP_INFO);
      }
    }
  }

  /**
   * Generate an info object that show the current state
   * of the map
   */
  getMapInfo(): MapInfoPayload {
    const { subsidiaryMode } = this.props;
    const {
      areas,
      selectedSubsidiaries,
      totalCirculation,
      price,
      weekpart,
    } = this.state;

    return {
      areas: subsidiaryMode ? selectedSubsidiaries : areas,
      totalCirculation,
      price,
      weekpart,
    } as MapInfoPayload;
  }

  /**
   * Get an array of all areas selected by all subsidiaries
   */
  getAllAreasFromSubsidiaries(): Area[] {
    const { selectedSubsidiaries } = this.state;
    return selectedSubsidiaries.reduce(
      (acc, selectedSubsidiary) => [...acc, ...selectedSubsidiary.areas],
      [] as Area[]
    ) as Area[];
  }

  /**
   * Get the total cirulation number of all selected areas
   */
  getTotalCirculation(): number {
    const { subsidiaryMode } = this.props;
    const { areas, selectedSubsidiaries } = this.state;

    if (subsidiaryMode)
      return selectedSubsidiaries.reduce(
        (acc: number, subsidiary: ClientLocation) => {
          let rAcc = acc;
          rAcc += calculateSubsidiaryCalculation(subsidiary);

          return rAcc;
        },
        0
      );

    return areas.reduce((acc: number, area: Area) => {
      let rAcc = acc;
      rAcc += calculateAreaCirculationTotal(area);
      return rAcc;
    }, 0);
  }

  /**
   * Get the total price of all selected areas
   */
  async getPrice(sendMessage?: boolean): Promise<TotalPrice | undefined> {
    const { subsidiaryMode } = this.props;
    const {
      selectedSubsidiaries,
      areas,
      selectedProduct,
      weekpart,
      client,
    } = this.state;

    if (!client?.showPrice && !config.general.showPrice) return undefined;

    const locations = subsidiaryMode
      ? getSubsidiariesSend(selectedSubsidiaries)
      : ([{ id: -1, areas: getAreasSend(areas) }] as SubsidiarySendFormat[]);

    const { price, subsidiaryPrices } = (await getTotalPrice(
      locations,
      weekpart,
      selectedProduct
    )) as PriceResult;

    selectedSubsidiaries.forEach(
      // eslint-disable-next-line no-return-assign
      subsidiary =>
        (subsidiary.price = subsidiaryPrices?.find(
          (subsidiaryPrice: LocationPrice) =>
            subsidiaryPrice.id === subsidiary.id
        ))
    );

    this.setState({ price });

    if (sendMessage)
      this.sendMessage(price, MessageType.MESSAGE_TYPE_UPDATE_PRICE);

    return price;
  }

  /**
   * Check if an areakey is selected by multiple subsidiaries
   * and return them
   *
   * @param areaKey
   */
  getMultiSelectionSubsidiaries(areaKey: string): ClientLocation[] {
    const { selectedSubsidiaries } = this.state;

    const subsidiaries = selectedSubsidiaries.filter(subsidiary =>
      subsidiary.areas.some((area: Area) => area.areaKey === areaKey)
    );

    return subsidiaries;
  }

  /**
   * Returns all area objects of an area that is selected
   * by mutliple subsidiaries
   *
   * @param area
   */
  getConflictingAreas(area: Area): Area[] {
    const conflictingSubsidiaries = this.getMultiSelectionSubsidiaries(
      area.areaKey
    );
    remove(conflictingSubsidiaries, { id: area.subsidiaryId });

    if (conflictingSubsidiaries.length > 0) {
      const conflictingAreas = conflictingSubsidiaries.reduce(
        (acc, subsidiary) => {
          const sArea = subsidiary.areas.find(
            fArea => fArea.areaKey === area.areaKey
          );
          if (sArea) return [...acc, ...[sArea]];
          return acc;
        },
        [] as Area[]
      );

      return conflictingAreas;
    }

    return [] as Area[];
  }

  /**
   * Sends an api call that returns all history items of
   * the currently selected client
   */
  async getClientHistory(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY);

    const order = client?.transmissionType === TRANSMISSION_TYPE_ORDER;

    const res = await getHistoryData(client, order);
    let history;

    if (!Number.isNaN(+res)) history = [] as OrderHistoryTemplate[];
    else if (order) history = res as OrderHistoryTemplate[];
    else history = res as OfferHistoryTemplate[];

    client.history = history;

    this.setState({ client }, () =>
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY)
    );
  }

  /**
   * Sends an api call that returns all client meta data
   */
  async getClientAreaMetaData(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_META);

    const res = await getClientAreaMetaData(client);
    let clientMetaData;

    if (!Number.isNaN(+res)) clientMetaData = {} as ClientMetaData;
    else clientMetaData = res as ClientMetaData;

    client.clientMetaData = clientMetaData;

    this.setState({ client }, () => {
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_META);

      const { current } = this.mapComponentRef;

      if (current) current.insertMetaData();
    });
  }

  /**
   * Sends an api call that returns all client locations
   */
  async getClientLocations(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);

    const res = await getClientLocations(client);
    let clientLocations;

    if (!Number.isNaN(+res)) clientLocations = [] as ClientLocation[];
    else clientLocations = res as ClientLocation[];

    client.clientLocations = clientLocations;

    this.setState({ client }, () => {
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_CLIENT_LOCATIONS);

      // If the client has at least one subsidiary set the first one selected
      if (
        client.clientLocations.length > 0 &&
        client.planningRestriction === 'NONE'
      ) {
        const firstSubsidiary = sortClientLocations(
          client.clientLocations
        ).filter(subsidiary => subsidiary.planable)[0];
        this.setSubsidiarySelected(firstSubsidiary);

        const { current } = this.mapComponentRef;

        if (current === null) return;

        client.clientLocations.map(location =>
          current.appendLocationToMap(location)
        );
      }
    });
  }

  /**
   * Sends an api call that returns all client sistribution templates
   */
  async getClientDistributionTemplates(): Promise<void> {
    const { client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_GET_DISTRIBUTION_TEMPLATES
    );

    const res = await getClientDistributionTemplates(client);
    let clientDistributionTemplates;

    if (!Number.isNaN(+res))
      clientDistributionTemplates = [] as DistributionTemplate[];
    else clientDistributionTemplates = res as DistributionTemplate[];

    client.distributionTemplates = clientDistributionTemplates;

    this.setState({ client }, () => {
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_GET_DISTRIBUTION_TEMPLATES
      );
    });
  }

  /**
   * Sends an api call that returns a feature
   * which represents the range one can reach
   * by a specific movement profile in a given
   * time.
   *
   * @param range
   * @param profile
   * @param dynamicPlaningParams
   */
  async getIsochrone(
    range: number,
    profile: OSRProfiles,
    dynamicPlaningParams: DynamicPlaningParam[]
  ): Promise<void> {
    const { client, selectedSubsidiaries } = this.state;

    const selectedSubsidiary = selectedSubsidiaries.find(
      subsidiary => subsidiary.selected
    );

    if (!selectedSubsidiary || !client) return;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_ISOCHRONE);

    const res = await getIsochrone(
      profile,
      range * 60,
      client,
      selectedSubsidiary
    );

    if (res) {
      const { current } = this.mapComponentRef;

      if (current !== null) current.markIsochrone(res, dynamicPlaningParams);
    } else this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_ISOCHRONE);
  }

  /**
   * Sends an api call with a dynamic parameters array
   *  through whic hthe selecteion will be optimized.
   *
   * @param areaDescription
   * @param dynamicPlaningParams
   */
  getDynamicAreas(
    areaDescription: AreaDescription[],
    dynamicPlaningParams: DynamicPlaningParam[]
  ): void {
    const { selectedSubsidiaries } = this.state;

    let selectedAreas: Area[] = [];
    const currentSubsidiary = selectedSubsidiaries.find(
      subsidiary => subsidiary.selected
    );
    if (currentSubsidiary) {
      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(
          currentSubsidiary.areas,
          false,
          currentSubsidiary.show
        );
      currentSubsidiary.areas = [] as Area[];

      addAreaStubs(currentSubsidiary.areas, areaDescription, currentSubsidiary);

      selectedAreas = this.getAllAreasFromSubsidiaries();
    }

    if (selectedAreas) {
      this.setState({ selectedSubsidiaries }, () =>
        this.updateAreaSelection(
          selectedAreas,
          true,
          true,
          [],
          dynamicPlaningParams
        )
      );
    }
  }

  /**
   * Sets the selection of localities for an area object.
   * This is also used to split localities between subsidiaries
   * that select the same area.
   *
   * @param area
   */
  setLocalitiesSelection(area: Area): void {
    const fArea = this.findArea(area);

    if (!fArea) return;

    fArea.localities.forEach(locality => {
      const fLocality = area.localities.find(
        pLocality => pLocality.localityKey === locality.localityKey
      );
      if (fLocality) locality.selected = fLocality.selected;
    });

    if (fArea.circulation >= 0)
      fArea.circulation = calculateAreaCirculation(fArea);

    const { weekpart, price, client } = this.state;

    fArea.circulationTotal = calculateAreaCirculationTotal(fArea);

    const conflictingSubsidiaries = this.getMultiSelectionSubsidiaries(
      area.areaKey
    );
    remove(conflictingSubsidiaries, { id: fArea.subsidiaryId });
    const conflictingAreas = this.getConflictingAreas(fArea);
    const totalCirculation = this.getTotalCirculation();

    if (conflictingAreas.length > 0) {
      conflictingAreas.forEach(conflictingArea => {
        conflictingArea.localities.forEach(locality => {
          const sLocality = area.localities.find(
            pLocality => pLocality.localityKey === locality.localityKey
          );
          if (sLocality && sLocality.selected && locality.selected)
            locality.selected = false;
        });

        conflictingArea.circulation = calculateAreaCirculation(conflictingArea);
        conflictingArea.circulationTotal = calculateAreaCirculationTotal(fArea);

        this.sendMessage(
          {
            area: conflictingArea,
            totalCirculation,
            weekpart,
            price,
          } as LocalityMessagePayload,
          MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES
        );
      });
    }

    this.setState(
      {
        lockSelection: false || client?.planningRestriction === 'TEMPLATE',
        totalCirculation,
      },
      () => {
        const { areas } = this.state;

        this.sendMessage(
          {
            ...(config.general.isShop ? { areas } : { area: fArea }),
            totalCirculation,
            weekpart,
          } as LocalityMessagePayload,
          MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES
        );
        this.getPrice(true);
      }
    );
  }

  /**
   * Sets a given subsidiary the currently selected one
   *
   * @param pSubsidiary
   */
  setSubsidiarySelected(pSubsidiary?: ClientLocation | Feature): void {
    const { client, lockSelection } = this.state;

    if (!client || (lockSelection && client.planningRestriction === 'TEMPLATE'))
      return;

    let subsidiary: ClientLocation | undefined;
    const { clientLocations } = client;

    if (pSubsidiary instanceof Feature) {
      // TODo find clientlocation with feature
      subsidiary = clientLocations.find((fSubsidiary: ClientLocation) => {
        const { name, street, housenumber, postcode, city } = fSubsidiary;
        return (
          (pSubsidiary as Feature).get(FEATURE_FIELD_SUBSIDIARY_NAME) ===
            name &&
          (pSubsidiary as Feature).get(FEATURE_FIELD_SUBSIDIARY_STREET) ===
            street &&
          (pSubsidiary as Feature).get(FEATURE_FIELD_SUBSIDIARY_HOUSENUMBER) ===
            housenumber &&
          (pSubsidiary as Feature).get(FEATURE_FIELD_SUBSIDIARY_POSTCODE) ===
            postcode &&
          (pSubsidiary as Feature).get(FEATURE_FIELD_SUBSIDIARY_CITY) === city
        );
      });
    } else {
      subsidiary = pSubsidiary;
    }

    const selectedSubsidiaries = clientLocations.filter(
      (selectedSubsidiary: ClientLocation) => selectedSubsidiary.selected
    );

    selectedSubsidiaries.forEach((selectedSubsidiary: ClientLocation) => {
      // eslint-disable-next-line no-unused-expressions
      selectedSubsidiary.feature?.setStyle(
        generateLocationStyle(
          selectedSubsidiary.poi.inactive ?? require('./resources/img/POI.png')
        )
      );
      selectedSubsidiary.selected = false;
    });

    this.showWarningMessage(false);

    if (subsidiary) {
      subsidiary = subsidiary as ClientLocation;

      // eslint-disable-next-line no-unused-expressions
      subsidiary.feature?.setStyle(
        generateLocationStyle(
          subsidiary.poi.active ?? require('./resources/img/POI.png')
        )
      );
      subsidiary.selected = true;
    }

    this.setState(
      {
        lockSelection:
          !subsidiary?.selected || client.planningRestriction === 'TEMPLATE',
      },
      () => {
        if (subsidiary) {
          this.zoomToSubsidiary(subsidiary);
          this.addSubsidiaryToSelection(subsidiary);
        }
      }
    );
  }

  /**
   * Changes the currently selected weekpart
   *
   * @param weekpart
   */
  setWeekpart(weekpart: Weekpart): void {
    const { subsidiaryMode } = this.props;
    this.setState(
      {
        weekpart,
      },
      () => {
        const { areas } = this.state;
        let selectedAreas = areas;

        if (subsidiaryMode) selectedAreas = this.getAllAreasFromSubsidiaries();
        this.updateAreaSelection(selectedAreas);
      }
    );
  }

  /**
   * Changes the currently selected product.
   *
   * @param selectedProduct
   */
  setSelectedProduct(selectedProduct: Product): void {
    this.setState({ selectedProduct }, () => {
      this.getPrice(true);
    });
  }

  /**
   * Search for the reference of an area in all
   * selected areas and subsidiaries.
   *
   * @param area
   */
  findArea(area: Area): Area | undefined {
    const { selectedSubsidiaries, areas } = this.state;
    const { subsidiaryMode } = this.props;
    let fArea: Area | undefined;
    if (subsidiaryMode) {
      const subsidiary = selectedSubsidiaries.find(
        fSubsidiary => fSubsidiary.id === area.subsidiaryId
      );
      if (!subsidiary) return fArea;

      fArea = subsidiary.areas.find(
        (subsidiaryArea: Area) => subsidiaryArea.areaKey === area.areaKey
      );

      if (!fArea) {
        forEach(subsidiary.areas, pArea => {
          fArea = pArea.additionalAreas.find(
            (aArea: Area) => aArea.areaKey === area.areaKey
          );
          if (fArea) return false;
          return true;
        });
      }
    } else {
      fArea = areas.find(pArea => pArea.areaKey === area.areaKey);

      if (!fArea) {
        forEach(areas, pArea => {
          fArea = pArea.additionalAreas.find(
            (aArea: Area) => aArea.areaKey === area.areaKey
          );
          if (fArea) return false;
          return true;
        });
      }
    }

    return fArea;
  }

  /**
   * Add a subsidiary to the array of selected subsidiaries
   *
   * @param subsidiary
   */
  addSubsidiaryToSelection(subsidiary: ClientLocation): void {
    const { selectedSubsidiaries } = this.state;

    if (
      !selectedSubsidiaries.find(
        fSubsidiary => fSubsidiary.id === subsidiary.id
      ) &&
      subsidiary.planable
    ) {
      if (!subsidiary.areas) subsidiary.areas = [] as Area[];

      selectedSubsidiaries.push(subsidiary);
      this.setState({ selectedSubsidiaries: [...selectedSubsidiaries] }, () =>
        this.sendMessage(subsidiary, MessageType.MESSAGE_TYPE_ADD_SUBSIDIARY)
      );
    }
  }

  /**
   * Removes a subsidiary from the array of selected subsidiaries
   *
   * @param subsidiary
   */
  removeSubsidiaryFromSelection(subsidiary: ClientLocation): void {
    const { selectedSubsidiaries, client } = this.state;

    const fSubsidiary = selectedSubsidiaries.find(
      pSubsidiary => pSubsidiary.id === subsidiary.id
    );

    if (!fSubsidiary) return;

    const removeAreas = fSubsidiary.areas;
    fSubsidiary.areas = [] as Area[];
    fSubsidiary.selected = false;
    fSubsidiary.price = undefined;
    remove(
      selectedSubsidiaries,
      selectedSubsidiary => selectedSubsidiary.id === fSubsidiary.id
    );
    this.setState(
      {
        lockSelection:
          selectedSubsidiaries.every(
            selectedSubsidiary => !selectedSubsidiary.selected
          ) || client?.planningRestriction === 'TEMPLATE',
        selectedSubsidiaries: [...selectedSubsidiaries],
      },
      () => {
        const areas = this.getAllAreasFromSubsidiaries();

        if ((areas && areas.length > 0) || removeAreas.length > 0) {
          this.updateAreaSelection(areas, false, false, removeAreas);
        } else {
          this.sendMessage(
            fSubsidiary,
            MessageType.MESSAGE_TYPE_REMOVE_SUBSIDIARY
          );
          this.setState({ totalCirculation: this.getTotalCirculation() });
        }
      }
    );
  }

  /**
   * Essentially checks if a planing restricted client can
   * add or remove a provieded area.
   *
   * @param areaDescription
   */
  addRemoveAreaRestrictedPlanning(
    area: AreaDescription | Area,
    removeArea: boolean
  ): void {
    const { selectedDistributionTemplate } = this.state;

    if (!selectedDistributionTemplate) return;

    const { locations } = selectedDistributionTemplate;

    const location = locations.find(fLocation =>
      fLocation.areas.find(fArea => fArea.areaKey === area.areaKey)
    );

    if (!location) return;

    if (removeArea) {
      this.removeArea({
        ...area,
        ...{ subsidiaryId: location.locationId },
      } as Area);
      return;
    }

    this.addArea(
      {
        ...area,
        ...{ subsidiaryId: location.locationId },
      } as AreaDescription,
      false
    );
  }

  /**
   * Adds an area to the selection
   *
   * @param areaDescription
   * @param ignoreConflictingAreas
   */
  addArea(
    areaDescription: AreaDescription,
    ignoreConflictingAreas: boolean = false
  ): void {
    const { areas, selectedSubsidiaries, client } = this.state;
    const { subsidiaryMode } = this.props;

    let selectedAreas;
    if (subsidiaryMode) {
      let currentSubsidiary;

      if (areaDescription.subsidiaryId)
        currentSubsidiary = selectedSubsidiaries.find(
          fSubsidiary => fSubsidiary.id === areaDescription.subsidiaryId
        );
      else
        currentSubsidiary = selectedSubsidiaries.find(
          fSubsidiary => fSubsidiary.selected
        );

      if (currentSubsidiary && currentSubsidiary.areas)
        addAreaStub(
          currentSubsidiary.areas,
          areaDescription,
          currentSubsidiary
        );
      else if (client?.planningRestriction === 'NONE')
        this.showWarningMessage(
          true,
          WARNING_MESSAGE_TITLE_NO_SUBSIDIARY_SELECTED,
          WARNING_MESSAGE_CONTENT_NO_SUBSIDIARY_SELECTED,
          WarningMessageType.WARN
        );

      selectedAreas = this.getAllAreasFromSubsidiaries();
    } else {
      addAreaStub(areas, areaDescription);
      selectedAreas = areas;
    }

    this.updateAreaSelection(
      selectedAreas,
      ignoreConflictingAreas,
      false,
      undefined
    );
  }

  /**
   * Add multiple areas to the selection
   *
   * @param areaDescriptions
   * @param appendToSelection
   * @param fitSelection
   * @param ignoreConflictingAreas
   */
  addAreas(
    areaDescriptions: AreaDescription[],
    appendToSelection: boolean = false,
    fitSelection?: boolean,
    ignoreConflictingAreas: boolean = false
  ): void {
    let { areas } = this.state;
    const { selectedSubsidiaries, client } = this.state;
    const { subsidiaryMode } = this.props;

    let selectedAreas: Area[] = [];
    if (subsidiaryMode) {
      const currentSubsidiary = selectedSubsidiaries.find(
        fSubsidiary => fSubsidiary.selected
      );
      if (currentSubsidiary) {
        if (!appendToSelection) {
          if (this.mapComponentRef.current !== null)
            this.mapComponentRef.current.markSelectedAreas(
              currentSubsidiary.areas,
              false,
              currentSubsidiary.show
            );
          currentSubsidiary.areas = [] as Area[];
        }
        addAreaStubs(
          currentSubsidiary.areas,
          areaDescriptions,
          currentSubsidiary
        );

        selectedAreas = this.getAllAreasFromSubsidiaries();
      } else if (client?.planningRestriction === 'NONE')
        this.showWarningMessage(
          true,
          WARNING_MESSAGE_TITLE_NO_SUBSIDIARY_SELECTED,
          WARNING_MESSAGE_CONTENT_NO_SUBSIDIARY_SELECTED,
          WarningMessageType.WARN
        );
    } else {
      if (!appendToSelection) {
        if (this.mapComponentRef.current !== null)
          this.mapComponentRef.current.markSelectedAreas(areas, false);
        areas = [] as Area[];
      }
      addAreaStubs(areas, areaDescriptions);

      selectedAreas = areas;
    }

    if (selectedAreas) {
      this.setState({ areas, selectedSubsidiaries }, () =>
        this.updateAreaSelection(
          selectedAreas,
          fitSelection,
          ignoreConflictingAreas
        )
      );
    }
  }

  /**
   * Add areas to the selection that were provided
   * on application start
   */
  addInitialAreas(): void {
    const { initAreas } = this.props;
    const { weekpart, client } = this.state;

    if (initAreas.length === 0) return;

    this.addAreas(
      initAreas.split(',').map(
        areaKey =>
          ({
            areaKey,
            weekpart,
            countryCode: COUNTRY_CODE_DE,
            type: client?.clientLayers[0].type ?? LAYER_TYPE_POSTCODE,
          } as AreaDescription)
      ),
      false,
      true
    );
  }

  /**
   * Adds the areas resulting from the perimeter selection
   * to a buffer. All previously selected areas that area
   * within the perimeter will be sorted out.
   * This method provides the ability to revert the perimeter
   * selection process.
   *
   * @param pPerimeterAreas
   */
  addPerimeterAreas(pPerimeterAreas: Area[]): void {
    const { subsidiaryMode } = this.props;
    const { areas } = this.state;

    let selectedAreas: Area[];

    if (subsidiaryMode) selectedAreas = this.getAllAreasFromSubsidiaries();
    else selectedAreas = areas;

    const perimeterAreas = pPerimeterAreas.filter(
      area =>
        !selectedAreas.find(
          selectedArea => selectedArea.areaKey === area.areaKey
        )
    );

    this.addAreas(perimeterAreas, true, true);

    this.showRevertPerimeter(true, perimeterAreas);

    setTimeout(() => {
      this.showRevertPerimeter(false, undefined);
    }, 20000);
  }

  /**
   * Adds a history item to the histrory list after the
   * user made an order or rquested an offer
   *
   * @param pHistory
   */
  addHistory(pHistory: any): void {
    const { client } = this.state;

    if (!client) return;

    let newHistory;
    if (client.transmissionType === TRANSMISSION_TYPE_ORDER)
      newHistory = extractOrderTemplate(pHistory);
    else newHistory = extractOfferTemplate(pHistory);

    const history = [...client.history, newHistory];

    client.history = sortHistoryTemplates(history);

    this.setState({ client });
  }

  /**
   * Checks if the current subsidiary has the given
   * area selected
   *
   * @param areaKey
   */
  isSelectedByCurrentSubsidiary(areaKey: string): boolean {
    const { selectedSubsidiaries } = this.state;
    const currentSubsidiary = selectedSubsidiaries.find(
      selectedSubsidiary => selectedSubsidiary.selected
    );

    if (!currentSubsidiary) return false;
    const subsidiaries = this.getMultiSelectionSubsidiaries(areaKey);

    return subsidiaries.some(
      subsidiary => subsidiary.id === currentSubsidiary.id
    );
  }

  /**
   * Restores the selection from an offer or order
   *
   * @param historyTemplate
   */
  async applyHistoryTemplate(
    historyTemplate: OrderHistoryTemplate | OfferHistoryTemplate
  ): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM);

    if (!client) return;

    const order = client?.transmissionType === TRANSMISSION_TYPE_ORDER;
    const historyIndex = client.history.findIndex(
      (history: HistoryTemplate) => history.id === historyTemplate.id
    );

    if (client.history[historyIndex].locations.length === 0) {
      const res = await getHistoryItemData(client, order, historyTemplate.id);
      let historyItem;

      if (!Number.isNaN(+res)) {
        // TODO error
        historyItem = {} as OrderHistoryTemplate;
      } else if (order) historyItem = res as OrderHistoryTemplate;
      else historyItem = res as OfferHistoryTemplate;

      client.history[historyIndex] = historyItem;
    }

    this.setState({ client }, () => {
      this.applyTemplate(client.history[historyIndex].locations);
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_GET_CLIENT_HISTORY_ITEM
      );
    });
  }

  /**
   * Restores the selection from a distribution template
   *
   * @param distributionTemplate
   */
  applyDistributionTemplate(distributionTemplate: DistributionTemplate): void {
    this.setState(
      {
        selectedDistributionTemplate: distributionTemplate,
      },
      () => {
        const { current } = this.mapComponentRef;
        if (current !== null) current.removeMarkedFeatures();

        this.applyTemplate(distributionTemplate.locations);
      }
    );
  }

  /**
   * Actual restore logic for templates and offers/orders
   *
   * @param distributionTemplateLocations
   */
  applyTemplate(
    distributionTemplateLocations: DistributionTemplateLocation[]
  ): void {
    const { client, weekpart } = this.state;

    if (!client) return;

    let { selectedSubsidiaries } = this.state;

    selectedSubsidiaries.forEach((selectedSubsidiary: ClientLocation) => {
      this.setSubsidiarySelected(undefined);

      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(
          selectedSubsidiary.areas,
          false,
          selectedSubsidiary.show
        );
      selectedSubsidiary.areas = [] as Area[];
    });

    selectedSubsidiaries = [];

    forEach(distributionTemplateLocations, pLocation => {
      const subsidiary = client.clientLocations.find(
        (fSubsidiary: ClientLocation) => fSubsidiary.id === pLocation.locationId
      );
      if (!subsidiary) return;
      subsidiary.areas = getAreaStubsFromTemplate(
        pLocation.areas,
        weekpart,
        subsidiary.id
      );

      selectedSubsidiaries.push(subsidiary);
    });

    this.setState({ selectedSubsidiaries }, () =>
      this.updateAreaSelection(this.getAllAreasFromSubsidiaries(), true)
    );
  }

  /**
   * If initial areas are provided this method adds
   * those areas to the current selection.
   *
   * @param data
   */
  applyInitAreas(data: InitAreasPayload): void {
    let { areas, selectedSubsidiaries } = this.state;
    const { client } = this.state;
    const { weekpart } = data;
    const initAreas = data.areas;

    if (!initAreas || initAreas.length === 0) return;

    let selectedAreas: Area[];

    if ((initAreas[0] as Area).areaKey) {
      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(areas, false);
      areas = initAreas as Area[];
      selectedAreas = areas;
    } else if ((initAreas[0] as ClientLocation).name && client) {
      if (this.mapComponentRef.current !== null)
        this.mapComponentRef.current.markSelectedAreas(
          this.getAllAreasFromSubsidiaries(),
          false
        );

      selectedSubsidiaries = [];

      forEach(
        initAreas as ClientLocation[],
        (clientLocation: ClientLocation) => {
          const subsidiary = client.clientLocations.find(
            (fSubsidiary: ClientLocation) =>
              fSubsidiary.id === clientLocation.id
          );
          if (!subsidiary) return;

          subsidiary.areas = clientLocation.areas;

          selectedSubsidiaries.push(subsidiary);
        }
      );

      selectedAreas = this.getAllAreasFromSubsidiaries();
    }

    this.setState({ selectedSubsidiaries, areas, weekpart }, () =>
      this.updateAreaSelection(selectedAreas, true, true)
    );
  }

  /**
   * Restores a subsidiary ditribution template
   *
   * @param subsidiaryDistributionTemplate
   */
  applySubsidiaryDistributionTemplate(
    subsidiaryDistributionTemplate: SubsidiaryDistributionTemplate
  ): void {
    const { client, weekpart } = this.state;

    if (!client) return;

    const { clientLocations } = client;

    const fClientLocation = clientLocations.find(
      (clientLocation: ClientLocation) =>
        clientLocation.id === subsidiaryDistributionTemplate.locationId
    );

    if (!fClientLocation) return;

    this.addSubsidiaryToSelection(fClientLocation);

    if (this.mapComponentRef.current !== null)
      this.mapComponentRef.current.markSelectedAreas(
        fClientLocation.areas,
        false,
        fClientLocation.show
      );
    fClientLocation.areas = [] as Area[];
    fClientLocation.areas = getAreaStubsFromTemplate(
      subsidiaryDistributionTemplate.areas,
      weekpart,
      fClientLocation.id
    );

    this.setState({ client }, () =>
      this.updateAreaSelection(this.getAllAreasFromSubsidiaries(), true)
    );
  }

  /**
   * Hides the selection of the given subsidiary on the map.
   * Areas won't be deselected, just hidden.
   *
   * @param subsidiary
   * @param show
   */
  hideSubsidiarySelection(
    subsidiary: ClientLocation | number,
    show: boolean
  ): void {
    const { selectedSubsidiaries } = this.state;
    const subsidiaryId = Number.isNaN(+subsidiary)
      ? (subsidiary as ClientLocation).id
      : (subsidiary as number);

    const fSubsidiary = selectedSubsidiaries.find(
      selectedSubsidiary => selectedSubsidiary.id === subsidiaryId
    );

    if (!fSubsidiary) return;

    fSubsidiary.show = show;

    this.setState(
      {
        selectedSubsidiaries,
      },
      () => {
        const { current } = this.mapComponentRef;
        if (current !== null) {
          current.markSelectedAreas(
            fSubsidiary.areas,
            true,
            fSubsidiary.show,
            fSubsidiary.colorSelectedFill
          );
          this.sendMessage(
            {
              subsidiaryId: fSubsidiary.id,
              show: fSubsidiary.show,
            } as HideSubsidiarySelectionPayload,
            MessageType.MESSAGE_TYPE_HIDE_SUBSIDIARY_SELECTION
          );
        }
      }
    );
  }

  /**
   * Removes a given area from the current selection
   *
   * @param area
   */
  removeArea(area: Area | undefined): void {
    if (!area) return;

    const { areas, selectedSubsidiaries } = this.state;
    const { subsidiaryMode } = this.props;
    let selectedAreas;
    let removedAreas;
    if (subsidiaryMode) {
      if (!area.subsidiaryId) {
        const currentSubsidiary = selectedSubsidiaries.find(
          selectedSubsidiary => selectedSubsidiary.selected
        );
        if (!currentSubsidiary) return;
        area.subsidiaryId = currentSubsidiary.id;
      }
      const subsidiary = selectedSubsidiaries.find(
        selectedSubsidiary => selectedSubsidiary.id === area.subsidiaryId
      );

      if (!subsidiary) return;

      removedAreas = remove(subsidiary.areas, { areaKey: area.areaKey });

      selectedAreas = this.getAllAreasFromSubsidiaries();
    } else {
      removedAreas = remove(areas, { areaKey: area.areaKey });
      selectedAreas = areas;
    }

    this.updateAreaSelection(selectedAreas, false, false, removedAreas);
  }

  /**
   * Removes multiple areas from the selection
   *
   * @param pAreas
   */
  removeAreas(pAreas: Area[] | undefined): void {
    if (!pAreas) return;

    const { areas, selectedSubsidiaries } = this.state;
    const { subsidiaryMode } = this.props;
    let selectedAreas = [] as Area[];
    let removedAreas = [] as Area[];

    if (subsidiaryMode) {
      pAreas.forEach(area => {
        if (!area.subsidiaryId) {
          const currentSubsidiary = selectedSubsidiaries.find(
            selectedSubsidiary => selectedSubsidiary.selected
          );
          if (!currentSubsidiary) return;
          area.subsidiaryId = currentSubsidiary.id;
        }
        const subsidiary = selectedSubsidiaries.find(
          selectedSubsidiary => selectedSubsidiary.id === area.subsidiaryId
        );

        if (!subsidiary) return;

        removedAreas = [
          ...remove(subsidiary.areas, { areaKey: area.areaKey }),
          ...removedAreas,
        ];
      });

      selectedAreas = this.getAllAreasFromSubsidiaries();
    } else {
      removedAreas = remove(areas, area =>
        pAreas.find(pArea => pArea.areaKey === area.areaKey)
      );
      selectedAreas = areas;
    }

    this.updateAreaSelection(selectedAreas, false, false, removedAreas);
  }

  /**
   * When the map layer type is switched convert or remove the
   * already selected areas to the new layer type. However if
   * no new layer type is submitted do nothing.
   *
   * @param compatible
   * @param newLayerType
   */
  convertAreaLayerType(compatible: boolean, newLayerType?: LayerType): void {
    const { subsidiaryMode } = this.props;
    const { applyLayerTypeToAll } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);

    if (!compatible) {
      this.removeAllAreas(false);
      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);

      return;
    }

    if (!applyLayerTypeToAll) {
      const { current } = this.mapComponentRef;

      if (current === null) return;

      if (subsidiaryMode) {
        const { selectedSubsidiaries } = this.state;

        this.findFeaturesForAreas(this.getAllAreasFromSubsidiaries(), true);

        this.setState({ selectedSubsidiaries }, () => {
          selectedSubsidiaries.forEach(subsidiary =>
            current.markSelectedAreas(
              subsidiary.areas,
              true,
              true,
              subsidiary.colorSelectedFill
            )
          );
        });
      } else {
        const { areas } = this.state;
        this.findFeaturesForAreas(areas);

        this.setState({ areas }, () => current.markSelectedAreas(areas, true));
      }

      this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);
      return;
    }

    let nAreas = [] as Area[];

    if (newLayerType) {
      if (subsidiaryMode) {
        const { selectedSubsidiaries } = this.state;

        selectedSubsidiaries.forEach(subsidiary => {
          const convertedAreas = changeAreasLayerType(
            getAreaDescriptions(subsidiary.areas),
            newLayerType
          );

          subsidiary.areas = [];

          addAreaStubs(subsidiary.areas, convertedAreas, subsidiary);
        });

        nAreas = this.getAllAreasFromSubsidiaries();
      } else {
        let { areas } = this.state;

        const convertedAreas = changeAreasLayerType(
          getAreaDescriptions(areas),
          newLayerType
        );
        areas = [];

        addAreaStubs(areas, convertedAreas);

        nAreas = areas;
      }
    }

    this.updateAreaSelection(nAreas, false, false);

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_CHANGE_LAYER_TYPE);
  }

  /**
   * Reverts the selection to the state before the perimeter
   * selection was performed.
   */
  revertPerimeter(): void {
    const { perimeterAreas } = this.state;

    this.removeAreas(perimeterAreas);
    this.showRevertPerimeter(false, undefined);
  }

  /**
   * Removes all areas from selection.
   *
   * Before the action is performed a confirmation
   * dialog will launch.
   */
  removeAllAreas(showWarning = true): void {
    if (showWarning)
      this.showConfirmationModal(
        true,
        CONFIRMATION_MODAL_TITLE_REMOVE_ALL_AREAS,
        CONFIRMATION_MODAL_CONTENT_REMOVE_ALL_AREAS,
        this.removeAllAreasCallback
      );
    else this.removeAllAreasCallback();
  }

  removeAllAreasCallback(): void {
    let { areas, selectedSubsidiaries } = this.state;
    const { subsidiaryMode } = this.props;
    const { current } = this.mapComponentRef;

    if (subsidiaryMode) {
      selectedSubsidiaries.forEach(selectedSubsidiary => {
        if (current !== null)
          current.markSelectedAreas(
            selectedSubsidiary.areas,
            false,
            selectedSubsidiary.show
          );

        selectedSubsidiary.selected = false;
        selectedSubsidiary.areas = [];
      });

      selectedSubsidiaries = [];
    } else {
      if (current !== null) {
        current.markSelectedAreas(areas, false);
      }

      areas = [];
    }

    this.setState(
      { areas, selectedSubsidiaries, totalCirculation: 0 },
      this.initMessage
    );
  }

  /**
   * Deletes a distribution template.
   *
   * Before the action is performed a confirmation
   * dialog will launch.
   *
   * @param distributionTemplate
   */
  removeDistributionTemplate(distributionTemplate: DistributionTemplate): void {
    this.showConfirmationModal(
      true,
      CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE,
      CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE,
      async () => {
        this.enableLoadingOverlay(
          true,
          REQUEST_IDENTIFIER_DELETE_DISTRIBUTION_TEMPLATE
        );
        const response = await deleteDistributionTemplate(distributionTemplate);
        if (response === 204) {
          const { client } = this.state;
          if (client) {
            remove(client.distributionTemplates, {
              id: distributionTemplate.id,
            });

            this.setState({ client });
          }
        }
        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_DELETE_DISTRIBUTION_TEMPLATE
        );
      }
    );
  }

  /**
   * Deletes a subsidiary distribution template.
   *
   * Before the action is performed a confirmation
   * dialog will launch.
   *
   * @param subsidiaryDistributionTemplate
   * @param subsidiary
   */
  async removeSubsidiaryDistributionTemplate(
    subsidiaryDistributionTemplate: SubsidiaryDistributionTemplate,
    subsidiary: ClientLocation
  ): Promise<void> {
    this.showConfirmationModal(
      true,
      CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE,
      CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE,
      async () => {
        const { client } = this.state;

        this.enableLoadingOverlay(
          true,
          REQUEST_IDENTIFIER_DELETE_SUBSIDIARY_DISTRIBUTION_TEMPLATE
        );

        if (client) {
          const fSubsidiary = client.clientLocations.find(
            location => location.id === subsidiary.id
          );

          if (fSubsidiary) {
            const res = await deleteSubsidiaryDistributionTemplate(
              client,
              subsidiary,
              subsidiaryDistributionTemplate
            );

            if (res < 300) {
              remove(fSubsidiary?.subsidiaryDistributionTemplates, {
                id: subsidiaryDistributionTemplate.id,
              });

              this.setState({ client });
            }
          }
        }

        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_DELETE_SUBSIDIARY_DISTRIBUTION_TEMPLATE
        );
      }
    );
  }

  /**
   * Requests information about the selected areas from the api
   *
   * @param selectedAreas
   * @param fitSelection
   * @param ignoreConflictingAreas
   * @param removedAreas
   * @param dynamicPlaningParams
   */
  async updateAreaSelection(
    selectedAreas: Area[],
    fitSelection: boolean = false,
    ignoreConflictingAreas: boolean = false,
    removedAreas?: Area[],
    dynamicPlaningParams?: DynamicPlaningParam[]
  ): Promise<void> {
    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_GET_AREA_DATA);
    const {
      areas,
      selectedSubsidiaries,
      weekpart,
      distributionWeek,
      distributionYear,
    } = this.state;
    const { subsidiaryMode } = this.props;

    this.findFeaturesForAreas(selectedAreas);

    // Request area data
    const newAreas = (await getAreaData(
      getAreaDataFormat(selectedAreas),
      weekpart,
      distributionWeek,
      distributionYear,
      dynamicPlaningParams
    )) as Area[];

    const { current } = this.mapComponentRef;
    let processedAreas = [] as Area[];

    if (current !== null) {
      // If there are removed areas deselect them on the map
      if (removedAreas && removedAreas.length > 0) {
        current.markSelectedAreas(removedAreas, false);
      } else {
        // Else search for differences between currently selected on the map
        // and the actually selected areas in the apps state
        current.markAreas(
          getAdditionalAreasDifference(
            subsidiaryMode ? this.getAllAreasFromSubsidiaries() : areas,
            newAreas
          ),
          false,
          true,
          undefined,
          true
        );
      }
    }

    if (newAreas) {
      if (subsidiaryMode) {
        // Check for each subsidiary if it contains one of the areas
        selectedSubsidiaries.forEach(selectedSubsidiary => {
          selectedSubsidiary.areas = this.processNewAreas(
            selectedSubsidiary.areas,
            newAreas,
            ignoreConflictingAreas
          );

          if (current !== null) {
            // Mark the newly selected areas on the map
            current.markSelectedAreas(
              selectedSubsidiary.areas,
              true,
              selectedSubsidiary.show,
              selectedSubsidiary.colorSelectedFill
            );
          }
        });
      } else {
        // Process the new areas
        processedAreas = this.processNewAreas(
          areas,
          newAreas,
          ignoreConflictingAreas
        );
        // Mark the new areas
        if (current !== null) current.markSelectedAreas(processedAreas, true);
      }

      this.setState({ selectedSubsidiaries, areas: processedAreas }, () => {
        if (fitSelection) this.fitSelection();
        this.setState(
          {
            totalCirculation: this.getTotalCirculation(),
          },
          () => {
            this.initMessage();
            // this.getPrice();
          }
        );
      });
    }
    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_AREA_DATA);
  }

  /**
   * update the overlaying portals area selection
   */
  async initMessage(): Promise<void> {
    const {
      selectedSubsidiaries,
      areas,
      weekpart,
      /* price, */ totalCirculation,
    } = this.state;
    const { subsidiaryMode } = this.props;

    const messagePayload = {} as AreaMessagePayload;
    if (subsidiaryMode) messagePayload.areas = selectedSubsidiaries;
    else messagePayload.areas = areas;

    messagePayload.weekpart = weekpart;
    messagePayload.price = await this.getPrice();
    messagePayload.totalCirculation = totalCirculation;

    this.sendMessage(messagePayload, MessageType.MESSAGE_TYPE_UPDATE_AREAS);
  }

  /**
   * Processes the received areas to the right format
   * as well as make a view checks and adjusments
   * (e.g. matching selected and delselected localities)
   *
   * @param areas
   * @param newAreas
   * @param ignoreConflictingAreas
   */
  processNewAreas(
    areas: Area[],
    newAreas: Area[],
    ignoreConflictingAreas: boolean = false
  ): Area[] {
    const returnAreas = [] as Area[];

    newAreas.forEach(pNewArea => {
      // Check if the area is completely new or already slected
      const newArea = cloneDeep(pNewArea);
      const match = areas.find(area => area.areaKey === newArea.areaKey);

      // If the area is already selected adopt previously made changes
      if (match) {
        if (match.feature) newArea.feature = match.feature;
        if (match.subsidiaryId) {
          const { subsidiaryId, countryCode, type } = match;
          newArea.subsidiaryId = subsidiaryId;
          newArea.additionalAreas.forEach(area => {
            area.subsidiaryId = subsidiaryId;
          });
          newArea.countryCode = countryCode;
          newArea.type = type;
        }

        // Check if the area is selected by multiple subsidaries
        const conflictingSubsidiaries = this.getMultiSelectionSubsidiaries(
          newArea.areaKey
        );
        remove(conflictingSubsidiaries, { id: newArea.subsidiaryId });

        newArea.localities.forEach(newLocality => {
          const oldLocality = match.localities.find(
            mLocality => mLocality.localityKey === newLocality.localityKey
          );
          if (oldLocality) newLocality.selected = oldLocality.selected;
        });

        if (match.id === -1 && conflictingSubsidiaries.length >= 1) {
          if (!ignoreConflictingAreas) {
            const conflictingAreas = this.getConflictingAreas(newArea);
            let hasConflicts = false;

            if (conflictingAreas.length > 0) {
              conflictingAreas.forEach(conflictingArea => {
                conflictingArea.localities.forEach(locality => {
                  const sLocality = newArea.localities.find(
                    fLocality => fLocality.localityKey === locality.localityKey
                  );
                  if (sLocality && sLocality.selected && locality.selected) {
                    sLocality.selected = false;
                    hasConflicts = true;
                  }
                });
              });

              // Split the localities between the selecting subsidiaries
              if (hasConflicts) this.showLocalitiesModal(newArea, true);
            }
          }
        }

        // Calculate the circulation of the new area
        if (newArea.circulation > 0)
          newArea.circulation = calculateAreaCirculation(newArea);

        newArea.circulationTotal = calculateAreaCirculationTotal(newArea);

        // Add the new area to the array of areas
        returnAreas.push(newArea);
      }
    });

    // Add corresponding features and layers to the area
    this.findFeaturesForAreas(
      returnAreas.filter(returnArea => !returnArea.feature || !returnArea.layer)
    );

    // Add corresponding features and layers to the areas additional areas
    this.findFeaturesForAreas(
      getAllAdditionalAreas(returnAreas).filter(
        returnAdditionalArea =>
          !returnAdditionalArea.feature || !returnAdditionalArea.layer
      )
    );
    return returnAreas;
  }

  /**
   * Enables and disbales the loading spinner.
   * To enable the loading animation a request identifier has to be submitted.
   * This will be added to an array. As long as this array is not empty
   * the anomation will be visible.
   * Everytime the loading parameter is false, a entry with the value of
   * the request identifier will be removed from the array.
   *
   * @param loading
   * @param requestIdentifier
   */
  enableLoadingOverlay(loading: boolean, requestIdentifier: string): void {
    const { pendingRequests } = this.state;

    let nPendingRequests = [] as string[];

    if (loading) nPendingRequests = [requestIdentifier, ...pendingRequests];
    else {
      const index = pendingRequests.indexOf(requestIdentifier);
      if (index < 0) nPendingRequests = pendingRequests;
      else {
        nPendingRequests = [
          ...pendingRequests.slice(0, index),
          ...pendingRequests.slice(index + 1),
        ];
      }
    }
    this.setState({
      pendingRequests: nPendingRequests,
      isLoading: nPendingRequests.length > 0,
    });
  }

  /**
   * Finds and adds corresponding features and layers for an area
   *
   * @param areas
   */
  findFeaturesForAreas(areas: Area[], forceNew?: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    areas.forEach(area => {
      if (!area.feature || !area.layer || forceNew) {
        const mapLink = current.findAreaKeyOnMap(area.areaKey);
        area.feature = mapLink.feature;
        area.layer = mapLink.layer;
      }
    });
  }

  /**
   * Enter or leave fullscreen.
   *
   * @param isFullscreen
   */
  changeFullscreen(isFullscreen: boolean): void {
    this.setState({
      isFullscreen,
    });
  }

  changeApplyLayerTypeToAll(applyLayerTypeToAll: boolean): void {
    this.setState({ applyLayerTypeToAll });
  }

  /**
   * Show or hide the import modal dialog
   * @param show
   */
  showImportModal(show: boolean): void {
    this.setState({
      showImportModal: show,
    });
  }

  /**
   * Toggle the subsidiary modal dialog and sets the client location
   *
   * @param subsidiary
   */
  showSubsidiaryModal(subsidiary?: ClientLocation): void {
    const { showSubsidiaryModal } = this.state;
    const { current } = this.clientLocationModalRef;

    if (current === null) return;

    if (subsidiary) current.setClientLocation(subsidiary);

    this.setState({ showSubsidiaryModal: !showSubsidiaryModal });
  }

  /**
   * Toggle the subsidiary distribution template modal and sets the client location
   * @param subsidiary
   */
  showDistributionTemplateModal(
    subsidiary?: ClientLocation,
    distirbutionTemplate?: DistributionTemplate
  ): void {
    const { showDistributionTemplateModal } = this.state;
    const { current } = this.templateModalRef;

    if (current === null) return;

    this.setState({
      showDistributionTemplateModal: !showDistributionTemplateModal,
    });
    current.setSubsidiary(subsidiary);
    current.setDistributionTemplate(distirbutionTemplate);
  }

  /**
   * Toggles the print map modal
   *
   * @param show
   */
  showPrintMapModal(): void {
    const { showPrintMapModal } = this.state;
    const { current } = this.templateModalRef;

    if (current === null) return;

    this.setState({
      showPrintMapModal: !showPrintMapModal,
    });
  }

  showLocalitiesModal(area: Area | undefined, show: boolean): void {
    const { current } = this.localitiesModalRef;

    if (current !== null && area) current.setArea(cloneDeep(area));

    this.setState({
      showLocalitiesModal: show,
    });
  }

  /**
   * Toggles the subsdiary list
   *
   * @param show
   */
  showSubsidiaryList(show: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showSubsidiaryList: show,
      showDistributionTemplateList: false,
      showHistoryList: false,
    });

    current.adjustMapSize();
  }

  /**
   * Toggles the distribution template list
   *
   * @param show
   */
  showDistributionTemplateList(show: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showDistributionTemplateList: show,
      showSubsidiaryList: false,
      showHistoryList: false,
    });

    current.adjustMapSize();
  }

  /**
   * Toggles the history list
   *
   * @param show
   */
  showHistoryList(show: boolean): void {
    const { current } = this.mapComponentRef;

    if (current === null) return;

    this.setState({
      showHistoryList: show,
      showSubsidiaryList: false,
      showDistributionTemplateList: false,
    });

    current.adjustMapSize();
  }

  /**
   * Shows or hides the confimation dialog.
   * Also the title, content and a callback have to be provided.
   *
   * @param showConfirmationModal
   * @param title
   * @param content
   * @param callback
   */
  showConfirmationModal(
    showConfirmationModal: boolean,
    title: string,
    content: string,
    callback: Function
  ): void {
    this.setState({
      showConfirmationModal,
      confirmationModalTitle: title ?? undefined,
      confirmationModalContent: content ?? undefined,
      confirmationCallback: callback ?? undefined,
    });
  }

  /**
   * Shows a warning message on the top of the map.
   * Title, content, and type have to be provided.
   *
   * @param showWarningMessage
   * @param title
   * @param content
   * @param type
   */
  showWarningMessage(
    showWarningMessage: boolean,
    title?: string,
    content?: string,
    type?: WarningMessageType
  ): void {
    const { current } = this.warningMessageRef;

    if (current === null) return;

    current.setContents(title, content, type);

    this.setState({
      showWarningMessage,
    });
  }

  /**
   * Shows or hides the subsidiary distribution modal dialog
   * and sets the corresponding subsidiary
   *
   * @param show
   * @param subsidiary
   */
  showSubsidiaryDistributionTemplates(
    show: boolean,
    subsidiary?: ClientLocation
  ): void {
    const { current } = this.subsidiaryDistributionTemplateModalRef;
    if (current === null) return;

    current.setSubsidiary(subsidiary);

    this.setState({ showSubsidiaryDistributionTemplateModal: show });
  }

  /**
   * Shows or hides the the response modal. Also title and
   * content have to be set here.
   *
   * @param showResponseModal
   * @param title
   * @param content
   */
  showResponseModal(
    showResponseModal: boolean,
    title: string,
    content: string
  ): void {
    this.setState({
      showResponseModal,
      responseModalTitle: title ?? undefined,
      responseModalContent: content ?? undefined,
    });
  }

  /**
   * Shows or hides the isochrone/dynamic planing modal dialog
   *
   * @param showIsochroneModal
   */
  showIsochroneModal(showIsochroneModal: boolean): void {
    this.setState({
      showIsochroneModal,
    });
  }

  /**
   * Shows or hides the layer type selection
   *
   * @param showIsochroneModal
   */
  showLayerTypeSelection(showLayerTypeSelection: boolean): void {
    const { current } = this.mapContainerRef;

    if (current === null) return;

    current.showLayerTypeSelection(showLayerTypeSelection);
  }

  /**
   * Completely expand or collapses the list of subsidiaries and
   * their areas in fullscreen mode.
   */
  showAllAreaItems(): void {
    const { showAllAreaItems } = this.state;

    this.setState({ showAllAreaItems: !showAllAreaItems });
  }

  /**
   * Shows or hides the button that can revert a perimeter selection.
   *
   * @param showRevertPerimeterButton
   * @param perimeterAreas
   */
  showRevertPerimeter(
    showRevertPerimeterButton: boolean,
    perimeterAreas?: Area[]
  ): void {
    this.setState({ showRevertPerimeterButton, perimeterAreas });
  }

  /**
   * Toggle the subsidiary list
   */
  toggleSubsidiaryList(): void {
    const { showSubsidiaryList } = this.state;
    this.showSubsidiaryList(!showSubsidiaryList);
  }

  /**
   * Toggle the distribution template list
   */
  toggleDistributionTemplateList(): void {
    const { showDistributionTemplateList } = this.state;
    this.showDistributionTemplateList(!showDistributionTemplateList);
  }

  /**
   * Toggle the history list
   */
  toggleHistoryList(): void {
    const { showHistoryList } = this.state;
    this.showHistoryList(!showHistoryList);
  }

  /**
   * Creates a new subsdiary
   *
   * @param subsidiary
   */
  async createSubsidiary(subsidiary: ClientLocationSend): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION);

    if (!client) return;

    const newSubsidiary = await createClientLocation(subsidiary, client);

    if (!newSubsidiary) {
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION
      );
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT()
      );
      return;
    }

    client.clientLocations.push(newSubsidiary);

    const { current } = this.mapComponentRef;
    if (current !== null) current.appendLocationToMap(newSubsidiary);

    this.setState({ client }, () =>
      this.enableLoadingOverlay(
        false,
        REQUEST_IDENTIFIER_CREATE_CLIENT_LOCATION
      )
    );
  }

  /**
   * Callback for the distribution template modal dialog that
   * triggers the restoration of the template.
   *
   * @param templateName
   * @param subsidiary
   */
  templateModalCallback(
    templateName: string,
    subsidiary?: ClientLocation
  ): void {
    if (subsidiary)
      this.createSubsidiaryDistributionTemplate(templateName, subsidiary);
    else this.createDistributionTemplate(templateName);
  }

  /**
   * Creates a new distribution template from the current selection.
   *
   * @param distributionTemplateName
   */
  async createDistributionTemplate(
    distributionTemplateName: string
  ): Promise<void> {
    const { selectedSubsidiaries, client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_CREATE_DISTRIBUTION_TEMPLATE
    );

    const newDistributionTemplate = (await createDistributionTemplate(
      client,
      getDistributionTemplate(selectedSubsidiaries, distributionTemplateName)
    )) as DistributionTemplate;

    if (newDistributionTemplate) {
      client.distributionTemplates.push(newDistributionTemplate);
      this.setState({ client });
    } else {
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT()
      );
    }

    this.enableLoadingOverlay(
      false,
      REQUEST_IDENTIFIER_CREATE_DISTRIBUTION_TEMPLATE
    );
  }

  updateDistributionTemplate(
    distributionTemplate: DistributionTemplate,
    updateAreas?: boolean,
    subsidiary?: ClientLocation
  ): void {
    if (updateAreas)
      this.showConfirmationModal(
        true,
        CONFIRMATION_MODAL_TITLE_OVERWRITE_DISTRIBUTION_TEMPLATE,
        CONFIRMATION_MODAL_CONTENT_OVERWRITE_DISTRIBUTION_TEMPLATE,
        () =>
          this.updateDistributionTemplateCallback(
            distributionTemplate,
            distributionTemplate.name,
            subsidiary,
            updateAreas
          )
      );
    else this.showDistributionTemplateModal(subsidiary, distributionTemplate);
  }

  /**
   * Update a new distribution template from the current selection.
   *
   * @param distributionTemplateName
   */
  async updateDistributionTemplateCallback(
    distributionTemplate: DistributionTemplate,
    distirbutionTemplateName: string,
    subsidiary?: ClientLocation,
    updateAreas?: boolean
  ): Promise<void> {
    const { selectedSubsidiaries, client } = this.state;

    if (!client) return;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_UPDATE_DISTRIBUTION_TEMPLATE
    );

    let uDistributionTemplate: DistributionTemplate;

    if (updateAreas) {
      uDistributionTemplate = getDistributionTemplate(
        selectedSubsidiaries,
        distirbutionTemplateName
      );
      uDistributionTemplate = {
        ...uDistributionTemplate,
        ...{ id: distributionTemplate.id },
      };
    } else
      uDistributionTemplate = {
        ...distributionTemplate,
        ...{ name: distirbutionTemplateName },
      };

    uDistributionTemplate = (await updateDistributionTemplate(
      client,
      uDistributionTemplate
    )) as DistributionTemplate;

    if (uDistributionTemplate) {
      const distributionTemplates = [...client.distributionTemplates];
      const index = distributionTemplates.findIndex(
        (template: DistributionTemplate) =>
          template.id === uDistributionTemplate.id
      );

      if (index > -1) {
        client.distributionTemplates = [
          ...distributionTemplates.slice(0, index),
          uDistributionTemplate,
          ...distributionTemplates.slice(index + 1),
        ];

        this.setState({ client });
      }
    } else {
      this.showResponseModal(
        true,
        RESPONSE_MODAL_FAILURE_TITLE,
        RESPONSE_MODAL_FAILURE_CONTENT()
      );
    }

    this.enableLoadingOverlay(
      false,
      REQUEST_IDENTIFIER_UPDATE_DISTRIBUTION_TEMPLATE
    );
  }

  /**
   * Enables drawing on the map
   */
  drawPerimeter(): void {
    const { current } = this.mapComponentRef;

    if (current !== null) {
      current.drawPerimeter();
    }
  }

  /**
   * Creates a new subsidiary distribution template
   *
   * @param subsidiaryDistributionTemplateName
   * @param subsidiary
   */
  async createSubsidiaryDistributionTemplate(
    subsidiaryDistributionTemplateName: string,
    subsidiary: ClientLocation
  ): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(
      true,
      REQUEST_IDENTIFIER_CREATE_SUBSIDIARY_DISTRIBUTION_TEMPLATE
    );

    if (client) {
      const fSubsidiary = client.clientLocations.find(
        clientLocation => clientLocation.id === subsidiary.id
      );

      if (fSubsidiary) {
        // TODO api call
        const res = (await createSubsidiaryDistributionTemplate(
          client,
          subsidiary,
          getSubsidiaryDistributionTemplate(
            fSubsidiary,
            subsidiaryDistributionTemplateName
          )
        )) as SubsidiaryDistributionTemplate;

        fSubsidiary.subsidiaryDistributionTemplates.push(res);
        this.setState({ client });
      }
    }

    this.enableLoadingOverlay(
      false,
      REQUEST_IDENTIFIER_CREATE_SUBSIDIARY_DISTRIBUTION_TEMPLATE
    );
  }

  /**
   * Updates a given subsidiary.
   *
   * @param subsidiary
   */
  async updateSubsidiary(subsidiary: ClientLocationSend): Promise<void> {
    const { client } = this.state;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION);

    if (client) {
      const updatedSubsidiary = (await updateClientLocation(
        subsidiary
      )) as ClientLocation;

      if (!updatedSubsidiary) return;

      const index = client.clientLocations.findIndex(
        clientLocation => clientLocation.id === updatedSubsidiary.id
      );

      if (index < 0) {
        this.enableLoadingOverlay(
          false,
          REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION
        );
        this.showResponseModal(
          true,
          RESPONSE_MODAL_FAILURE_TITLE,
          RESPONSE_MODAL_FAILURE_CONTENT()
        );
        return;
      }

      const { current } = this.mapComponentRef;
      const {
        addressName,
        city,
        colorSelectedFill,
        housenumber,
        email,
        id,
        lat,
        lon,
        name,
        number,
        openingHours,
        phone,
        planable,
        poi,
        postcode,
        street,
      } = updatedSubsidiary;

      let indexClientLocation = client.clientLocations[index];

      if (current !== null) current.removeLocationFromMap(indexClientLocation);

      indexClientLocation = {
        ...indexClientLocation,
        ...{
          addressName,
          city,
          colorSelectedFill,
          email,
          housenumber,
          id,
          lat,
          lon,
          name,
          number,
          openingHours,
          phone,
          planable,
          poi,
          postcode,
          street,
        },
      };

      if (current !== null) {
        current.markSelectedAreas(
          indexClientLocation.areas,
          true,
          indexClientLocation.show,
          indexClientLocation.colorSelectedFill
        );
        current.appendLocationToMap(indexClientLocation);
      }

      client.clientLocations[index] = indexClientLocation;

      this.setState({ client });
    }

    this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_UPDATE_CLIENT_LOCATION);
  }

  /**
   * Triggers the export of the selection as a CSV file
   */
  exportCSV(): void {
    const { subsidiaryMode } = this.props;
    const { areas, client, selectedSubsidiaries } = this.state;

    if (this.getAllAreasFromSubsidiaries().length === 0 && areas.length === 0) {
      this.showWarningMessage(
        true,
        WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
        WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
        WarningMessageType.WARN
      );

      return;
    }

    if (client && subsidiaryMode)
      exportSubsidiaryCSV(selectedSubsidiaries, client.name);
    else exportAreaCSV(areas);
  }

  /**
   * Triggers the export of the selection as an Excel file
   */
  exportExcel(): void {
    const { userMail, subsidiaryMode } = this.props;
    const { client, weekpart, selectedSubsidiaries, areas } = this.state;

    if (this.getAllAreasFromSubsidiaries().length === 0 && areas.length === 0) {
      this.showWarningMessage(
        true,
        WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
        WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
        WarningMessageType.WARN
      );

      return;
    }

    if (client)
      exportExcel(
        client,
        client.name,
        weekpart,
        userMail ?? '',
        subsidiaryMode ? selectedSubsidiaries : undefined,
        subsidiaryMode ? undefined : areas
      );
  }

  /**
   * Deletes a subsidiary.
   *
   * @param subsidiary
   */
  deleteSubsidiary(subsidiary: ClientLocation): void {
    this.showConfirmationModal(
      true,
      CONFIRMATION_MODAL_TITLE_DELETE_SUBSIDIARY,
      CONFIRMATION_MODAL_CONTENT_DELETE_SUBSIDIARY,
      () => {
        const { client } = this.state;

        if (!client) return;

        deleteClientLocation(subsidiary);

        remove(
          client.clientLocations,
          location => location.id === subsidiary.id
        );
        this.removeSubsidiaryFromSelection(subsidiary);

        const { current } = this.mapComponentRef;
        if (current) current.removeLocationFromMap(subsidiary);
        this.setState({ client });
      }
    );
  }

  /**
   * Centers the map to the users position.
   */
  async centerLocation(): Promise<void> {
    const currentMapComponent = this.mapComponentRef.current;

    if (navigator.geolocation)
      await navigator.geolocation.getCurrentPosition(
        location => {
          const lat = location.coords.latitude;
          const lon = location.coords.longitude;
          const coordinates = { lon, lat } as Coordinates;

          if (currentMapComponent !== null) {
            currentMapComponent.zoomToCoordinates(coordinates);
          }
        },
        // eslint-disable-next-line no-console
        error => console.log('error', error)
      );
  }

  /**
   * Fit the map to the extend of the selected areas
   */
  fitSelection(): void {
    const { areas, selectedSubsidiaries } = this.state;
    const { subsidiaryMode } = this.props;

    const { current } = this.mapComponentRef;
    if (current !== null) {
      let selectedAreas;
      if (subsidiaryMode && selectedSubsidiaries) {
        selectedAreas = this.getAllAreasFromSubsidiaries();
      } else if (areas) selectedAreas = areas;

      current.fitFeatures(getAllAreaFeatures(selectedAreas));
    }
  }

  /**
   *  Triggers the export of the current selection as PDF file
   */
  printSelection(paperSize: PaperSize, selectedResolution: number): void {
    const { subsidiaryMode } = this.props;
    const { selectedSubsidiaries, areas } = this.state;
    const { current } = this.mapComponentRef;

    this.enableLoadingOverlay(true, REQUEST_IDENTIFIER_PRINT_MAP);

    if (current === null) return;
    if (areas || selectedSubsidiaries) {
      let areasToPrint;
      if (subsidiaryMode && selectedSubsidiaries) {
        areasToPrint = this.getAllAreasFromSubsidiaries();
      } else areasToPrint = areas;

      if (areasToPrint.length === 0) {
        this.showWarningMessage(
          true,
          WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION,
          WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION,
          WarningMessageType.WARN
        );
        this.enableLoadingOverlay(false, REQUEST_IDENTIFIER_PRINT_MAP);
      } else
        current.printSelection(
          getAllAreaFeatures(areasToPrint),
          paperSize,
          selectedResolution
        );
    }
  }

  /**
   * Send messages to the overlaying portal (e.g. FPP)
   *
   * @param payload
   * @param type
   */
  sendMessage(payload: MessagePayload, type: MessageType): void {
    const { parentOrigin, subsidiaryMode } = this.props;
    let payloadCopy = cloneDeep(payload);

    if (
      type === MessageType.MESSAGE_TYPE_ADD_SUBSIDIARY ||
      type === MessageType.MESSAGE_TYPE_REMOVE_SUBSIDIARY
    ) {
      payloadCopy = removeNotSerializableFromSubsidiary(
        payloadCopy as ClientLocation
      );
    } else if (type === MessageType.MESSAGE_TYPE_UPDATE_AREAS) {
      if (subsidiaryMode)
        payloadCopy.areas = removeNotSerializableFromSubsidiaries(
          (payloadCopy as AreaMessagePayload).areas as ClientLocation[]
        );
      else
        payloadCopy.areas = removeNotSerializableFromAreas(
          (payloadCopy as AreaMessagePayload).areas as Area[]
        );
    } else if (type === MessageType.MESSAGE_TYPE_UPDATE_LOCALITIES) {
      payloadCopy = payloadCopy as LocalityMessagePayload;

      if (payloadCopy.area) {
        (payloadCopy.area as Area).feature = undefined;
        (payloadCopy.area as Area).layer = undefined;
      } else {
        payloadCopy.areas = removeNotSerializableFromAreas(payloadCopy.areas);
      }
    } else if (type === MessageType.MESSAGE_TYPE_CHANGE_WEEKPART) {
      // TODO
    } else if (type === MessageType.MESSAGE_TYPE_UPDATE_PRICE) {
      // TODO
    } else if (type === MessageType.MESSAGE_TYPE_HIDE_SUBSIDIARY_SELECTION) {
      // TODO
    } else if (type === MessageType.MESSAGE_TYPE_MAP_INFO) {
      payloadCopy = payloadCopy as MapInfoPayload;

      if (subsidiaryMode)
        payloadCopy.areas = removeNotSerializableFromSubsidiaries(
          payloadCopy.areas as ClientLocation[]
        );
      else
        payloadCopy.areas = removeNotSerializableFromAreas(
          payloadCopy.areas as Area[]
        );
    }

    const message = { payload: payloadCopy, type } as Message;

    if (parentOrigin !== '') window.parent.postMessage(message, parentOrigin);
  }

  /**
   * Toggles the weekpart according to the order in the array
   */
  toggleWeekpart(): void {
    const { weekpart, client } = this.state;
    const { subsidiaryMode } = this.props;

    const weekparts = client?.weekparts ?? WEEKPART_ARRAY;
    const currentIndex = (weekparts?.indexOf(weekpart) ?? -1) + 1;
    const newWeekpart =
      weekparts[currentIndex === -1 ? 0 : currentIndex % weekparts.length];

    this.setState(
      {
        weekpart: newWeekpart,
      },
      () => {
        const { areas } = this.state;

        let selectedAreas;
        if (subsidiaryMode) selectedAreas = this.getAllAreasFromSubsidiaries();
        else selectedAreas = areas;
        this.updateAreaSelection(selectedAreas);
      }
    );
  }

  /**
   * Sets the focus of the map to a given subsidiary
   *
   * @param subsidiary
   */
  zoomToSubsidiary(subsidiary: ClientLocation): void {
    const currentMapComponent = this.mapComponentRef.current;
    if (currentMapComponent !== null) {
      const { lon, lat } = subsidiary;
      currentMapComponent.zoomToCoordinates({ lat, lon });
    }
  }

  /**
   * Sets the focus of the map to a given address. This
   * method is mainly used by the address search.
   *
   * @param address
   */
  zoomToAddress(address: Address): void {
    const currentMapComponent = this.mapComponentRef.current;
    if (currentMapComponent !== null) {
      const { lon, lat } = address.coordinates;
      currentMapComponent.zoomToCoordinates({ lat, lon });
    }
  }

  // TODO Error dialog (pdf creation, requests, ...)
  render(): JSX.Element {
    const {
      client,
      totalCirculation,
      areas,
      selectedSubsidiaries,
      isFullscreen,
      isLoading,
      selectedDistributionTemplate,
      lockSelection,
      showSubsidiaryList,
      showDistributionTemplateList,
      showHistoryList,
      showImportModal,
      showDistributionTemplateModal,
      showSubsidiaryModal,
      showLocalitiesModal,
      showConfirmationModal,
      showSubsidiaryDistributionTemplateModal,
      showIsochroneModal,
      showWarningMessage,
      showPrintMapModal,
      showRevertPerimeterButton,
      confirmationModalTitle,
      confirmationModalContent,
      confirmationCallback,
      showResponseModal,
      responseModalTitle,
      responseModalContent,
      weekpart,
      price,
      showAllAreaItems,
      applyLayerTypeToAll,
    } = this.state;
    const { subsidiaryMode } = this.props;

    return (
      <div className="h-100 w-100">
        <Row
          ref={this.fullScreenContainerRef}
          id="mapFullscreenContainer"
          className="h-100 w-100 no-gutters"
        >
          {isLoading && (
            <LoadingOverlay
              loadingTitle={LOADING_PLEASE_WAIT}
              loadingSubtitle={LOADING_PROCESS_REQUEST}
            />
          )}
          <WarningMessage
            ref={this.warningMessageRef}
            show={showWarningMessage}
            closeMessage={this.showWarningMessage}
          />
          <ConfirmationModal
            callback={confirmationCallback}
            container={this.fullScreenContainerRef}
            content={confirmationModalContent}
            show={showConfirmationModal}
            title={confirmationModalTitle}
            showModal={this.showConfirmationModal}
          />
          <ResponseModal
            container={this.fullScreenContainerRef}
            content={responseModalContent}
            show={showResponseModal}
            title={responseModalTitle}
            showModal={this.showResponseModal}
          />
          <ClientLocationModal
            ref={this.clientLocationModalRef}
            container={this.fullScreenContainerRef}
            show={showSubsidiaryModal}
            newSubsidiary={this.createSubsidiary}
            showModal={this.showSubsidiaryModal}
            updateSusidiary={this.updateSubsidiary}
            enableLoadingOverlay={this.enableLoadingOverlay}
          />
          <ImportModal
            client={client}
            container={this.fullScreenContainerRef}
            show={showImportModal}
            weekpart={weekpart}
            addAreas={this.addAreas}
            showModal={this.showImportModal}
          />
          <LocalitiesModal
            ref={this.localitiesModalRef}
            container={this.fullScreenContainerRef}
            show={showLocalitiesModal}
            weekpart={weekpart}
            confirmLocalitySelection={this.setLocalitiesSelection}
            showModal={this.showLocalitiesModal}
          />
          <PrintMapModal
            container={this.fullScreenContainerRef}
            show={showPrintMapModal}
            showModal={this.showPrintMapModal}
            printMap={this.printSelection}
          />
          <DistributionTemplateModal
            ref={this.templateModalRef}
            container={this.fullScreenContainerRef}
            show={showDistributionTemplateModal}
            newDistributionTemplate={this.templateModalCallback}
            updateistributionTemplate={this.updateDistributionTemplateCallback}
            showModal={this.showDistributionTemplateModal}
          />
          <SubsidiaryDistributionTemplateModal
            ref={this.subsidiaryDistributionTemplateModalRef}
            container={this.fullScreenContainerRef}
            show={showSubsidiaryDistributionTemplateModal}
            applySubsidiaryDistributionTemplate={
              this.applySubsidiaryDistributionTemplate
            }
            removeSubsidiaryDistributionTemplate={
              this.removeSubsidiaryDistributionTemplate
            }
            showModal={this.showSubsidiaryDistributionTemplates}
          />
          <IsochroneModal
            container={this.fullScreenContainerRef}
            show={showIsochroneModal}
            dynamicPlaningParams={DUMMY_DYN_PARAMS}
            getIsochrone={this.getIsochrone}
            showModal={this.showIsochroneModal}
          />
          {client?.clientLocations && (
            <SubsidiaryListContainer
              planningRestriction={client?.planningRestriction ?? 'NONE'}
              showSubsidiaryList={showSubsidiaryList}
              subsidiaries={client.clientLocations.filter(
                subsidiary => subsidiary.planable
              )}
              changeSubsidiaryColor={this.updateSubsidiary}
              deleteSubsidiary={this.deleteSubsidiary}
              hideSubsidiaryList={this.showSubsidiaryList}
              setSubsidiarySelected={this.setSubsidiarySelected}
              showNewSubsidiaryDialog={this.showSubsidiaryModal}
              showSubsidiaryDistributionTemplates={
                this.showSubsidiaryDistributionTemplates
              }
              showSubsidiaryDistributionTemplateModal={
                this.showDistributionTemplateModal
              }
              zoomToSubsidiary={this.zoomToSubsidiary}
            />
          )}
          {client?.distributionTemplates && (
            <DistributionTemplateListContainer
              distributionTemplates={client.distributionTemplates}
              planningRestriction={client?.planningRestriction ?? 'NONE'}
              showDistributionTemplates={showDistributionTemplateList}
              applyDistributionTemplate={this.applyDistributionTemplate}
              updateDistributionTemplate={this.updateDistributionTemplate}
              deleteDistributionTemplate={this.removeDistributionTemplate}
              hideDistributionTemplateList={this.showDistributionTemplateList}
              showNewDistributionTemplateModal={
                this.showDistributionTemplateModal
              }
            />
          )}
          {client?.history && (
            <HistoryContainer
              historyTemplates={client.history}
              showHistoryTemplates={showHistoryList}
              transmissionType={client.transmissionType}
              applyHistoryTemplate={this.applyHistoryTemplate}
              hideHistoryList={this.showHistoryList}
            />
          )}
          <Col className="p-0 h-100">
            <MapMenu
              sideMenuExpanded={
                showSubsidiaryList ||
                showDistributionTemplateList ||
                showHistoryList
              }
              layerTypes={client?.layerTypes}
              planningRestriction={client?.planningRestriction ?? 'NONE'}
              weekpart={weekpart}
              transmissionType={client?.transmissionType}
              subsidiaryMode={subsidiaryMode}
              showSubsidiaryList={showSubsidiaryList}
              showDistributionTemplateList={showDistributionTemplateList}
              showHistoryList={showHistoryList}
              weekparts={client?.weekparts ?? WEEKPART_ARRAY}
              showRevertPerimeterButton={showRevertPerimeterButton}
              centerLocation={this.centerLocation}
              drawPerimeter={this.drawPerimeter}
              exportCSV={this.exportCSV}
              exportExcel={this.exportExcel}
              fitSelection={this.fitSelection}
              printSelection={this.showPrintMapModal}
              revertPerimeter={this.revertPerimeter}
              showImportModal={this.showImportModal}
              showIsochroneModal={this.showIsochroneModal}
              toggleDistributionTemplates={this.toggleDistributionTemplateList}
              toggleHistory={this.toggleHistoryList}
              toggleSubsidiaries={this.toggleSubsidiaryList}
              toggleWeekpart={this.toggleWeekpart}
              zoomToAddress={this.zoomToAddress}
              showLayerTypeSelection={this.showLayerTypeSelection}
            />
            <MapContainer
              ref={this.mapContainerRef}
              mapRef={this.mapComponentRef}
              client={client}
              lockSelection={lockSelection}
              subsidiaryMode={subsidiaryMode}
              weekpart={weekpart}
              selectedDistributionTemplate={selectedDistributionTemplate}
              applyToAll={applyLayerTypeToAll}
              changeAreaLayerType={this.convertAreaLayerType}
              addRemoveAreaRestrictedPlanning={
                this.addRemoveAreaRestrictedPlanning
              }
              addArea={this.addArea}
              addInitAreas={this.addInitialAreas}
              addPerimeterAreas={this.addPerimeterAreas}
              changeFullscreen={this.changeFullscreen}
              enableLoadingOverlay={this.enableLoadingOverlay}
              getDynamicAreas={this.getDynamicAreas}
              getMultiSelectionSubsidiaries={this.getMultiSelectionSubsidiaries}
              isSelectedByCurrentSubsidiary={this.isSelectedByCurrentSubsidiary}
              removeArea={this.removeArea}
              selectSubsidiary={this.setSubsidiarySelected}
              changeApplyToAll={this.changeApplyLayerTypeToAll}
              showConfimationModal={this.showConfirmationModal}
            />
          </Col>
          {isFullscreen && (
            <Col
              md={3}
              className="p-0 area-list-section h-100 d-flex flex-column"
            >
              <Row className="no-gutters px-2 pt-4">
                <Col>
                  <AreaListHeader
                    showAll={showAllAreaItems}
                    showCollapseAll={subsidiaryMode}
                    collapseAll={this.showAllAreaItems}
                    removeAll={this.removeAllAreas}
                  />
                </Col>
              </Row>
              <Row className="no-gutters flex-grow-1 area-list-container px-2 pb-2">
                <Col md={12} className="h-100">
                  {subsidiaryMode ? (
                    <SubsidiaryAreaList
                      showAll={showAllAreaItems}
                      subsidiaries={selectedSubsidiaries}
                      weekpart={weekpart}
                      hideSubsidiarySelection={this.hideSubsidiarySelection}
                      removeArea={this.removeArea}
                      removeSubsidiary={this.removeSubsidiaryFromSelection}
                      showLocalities={this.showLocalitiesModal}
                    />
                  ) : (
                    <AreaList
                      show
                      areas={areas}
                      removeArea={this.removeArea}
                      showLocalities={this.showLocalitiesModal}
                      weekpart={weekpart}
                    />
                  )}
                </Col>
              </Row>
              <Row className="no-gutters p-2">
                <Col md={12}>
                  {(client?.showPrice || config.general.showPrice) && (
                    <PriceItem price={price} />
                  )}
                </Col>
              </Row>
              <Row className="no-gutters p-2">
                <Col md={12}>
                  <CirculationItem
                    weekpart={weekpart}
                    circulation={totalCirculation}
                    totalCirculation
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

export default App;
