import * as React from 'react';

import { Col, Button, Form, ListGroup, Spinner, Row } from 'react-bootstrap';
import Select from 'react-select';
import ColorPicker from '../ColorPicker';

import { rgbTorgbObject, rgbTorgba } from '../../../util/featureStyle';
import {
  SUBSIDIARY_MODAL_NAME_TITLE,
  SUBSIDIARY_MODAL_NAME_PLACEHOLDER,
  SUBSIDIARY_MODAL_NAME_INVALID,
  SUBSIDIARY_MODAL_POSTCODE_TITLE,
  SUBSIDIARY_MODAL_POSTCODE_PLACEHOLDER,
  SUBSIDIARY_MODAL_POSTCODE_INVALID,
  SUBSIDIARY_MODAL_NUMBER_TITLE,
  SUBSIDIARY_MODAL_NUMBER_PLACEHOLDER,
  SUBSIDIARY_MODAL_NUMBER_INVALID,
  SUBSIDIARY_MODAL_CITY_TITLE,
  SUBSIDIARY_MODAL_CITY_PLACEHOLDER,
  SUBSIDIARY_MODAL_CITY_INVALID,
  SUBSIDIARY_MODAL_STREET_TITLE,
  SUBSIDIARY_MODAL_STREET_PLACEHOLDER,
  SUBSIDIARY_MODAL_STREET_INVALID,
  SUBSIDIARY_MODAL_HOUSENUMBER_TITLE,
  SUBSIDIARY_MODAL_HOUSENUMBER_PLACEHOLDER,
  SUBSIDIARY_MODAL_HOUSENUMBER_INVALID,
  SUBSIDIARY_MODAL_ADDRESS_NAME_TITLE,
  SUBSIDIARY_MODAL_ADDRESS_NAME_PLACEHOLDER,
  SUBSIDIARY_MODAL_ADDRESS_NAME_INVALID,
  SUBSIDIARY_MODAL_VERIFY_ADDRESS,
  SUBSIDIARY_MODAL_VERIFY_ADDRESS_TITLE,
  SUBSIDIARY_MODAL_COLOR,
  SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION,
  SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED,
  SUBSIDIARY_MODAL_EMAIL_TITLE,
  SUBSIDIARY_MODAL_EMAIL_PLACEHOLDER,
  SUBSIDIARY_MODAL_PHONE_PLACEHOLDER,
  SUBSIDIARY_MODAL_PHONE_TITLE,
  SUBSIDIARY_MODAL_POI_TITLE,
  SUBSIDIARY_MODAL_POI_PLACEHOLDER,
} from '../../../constants/labels';
import { selectPickerTheme } from '../../../constants/constants';

import { ClientLocationDataProps } from '../../../@types/Modal.d';
import { IconDropDownOption } from '../../../@types/App.d';
import { POI } from '../../../@types/Common.d';

const poiIconSelectOption: React.FC<IconDropDownOption> = (
  props: IconDropDownOption
) => {
  const { data, label } = props;
  return (
    <div className="icon-dropdown-option">
      <div>
        <img src={data.active} alt={`${label}`} />
      </div>
      <div>{`${label}`}</div>
    </div>
  );
};

const ClientLocationData: React.FC<ClientLocationDataProps> = (
  props: ClientLocationDataProps
) => {
  const {
    addressName,
    id,
    addressLoading,
    addresses,
    colorSelectedFill,
    showPicker,
    name,
    number,
    postcode,
    city,
    housenumber,
    street,
    coordCheckValue,
    email,
    phone,
    poi,
    pois,
    onChangeName,
    onChangeNumber,
    onChangeAddressName,
    onChangePostCode,
    onChangeCity,
    onChangeStreet,
    onChangeHousenumber,
    onChangeColor,
    onChangeEmail,
    onChangePhone,
    onChangePOI,
    onClickAddressSearch,
    onClickAddressItem,
    showColorPicker,
  } = props;

  const availablePOIs = pois.map(
    (pPoi: POI) =>
      ({
        label: pPoi?.name ?? '',
        value: pPoi.id ?? '',
        data: pPoi,
      } as IconDropDownOption)
  );

  return (
    <Row className="no-gutters">
      <Col md={9}>
        <Form.Row>
          <Col>
            <Form.Group controlId="input-name">
              <Form.Label>{SUBSIDIARY_MODAL_NAME_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="organization"
                onChange={onChangeName}
                value={name}
                placeholder={SUBSIDIARY_MODAL_NAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="input-number">
              <Form.Label>{SUBSIDIARY_MODAL_NUMBER_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="new-password"
                onChange={onChangeNumber}
                value={number}
                placeholder={SUBSIDIARY_MODAL_NUMBER_PLACEHOLDER}
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_NUMBER_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={6}>
            <Form.Group controlId="input-address-name">
              <Form.Label>{SUBSIDIARY_MODAL_ADDRESS_NAME_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="new-password"
                onChange={onChangeAddressName}
                value={addressName}
                placeholder={SUBSIDIARY_MODAL_ADDRESS_NAME_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_ADDRESS_NAME_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId="input-name">
              <Form.Label>{SUBSIDIARY_MODAL_EMAIL_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="email"
                onChange={onChangeEmail}
                value={email}
                placeholder={SUBSIDIARY_MODAL_EMAIL_PLACEHOLDER}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="input-number">
              <Form.Label>{SUBSIDIARY_MODAL_PHONE_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="tel"
                onChange={onChangePhone}
                value={phone}
                placeholder={SUBSIDIARY_MODAL_PHONE_PLACEHOLDER}
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col md={4}>
            <Form.Group controlId="input-postcode">
              <Form.Label>{SUBSIDIARY_MODAL_POSTCODE_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="postal-code"
                onChange={onChangePostCode}
                value={postcode}
                placeholder={SUBSIDIARY_MODAL_POSTCODE_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_POSTCODE_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form.Group controlId="input-city">
              <Form.Label>{SUBSIDIARY_MODAL_CITY_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="address-level2"
                onChange={onChangeCity}
                value={city}
                placeholder={SUBSIDIARY_MODAL_CITY_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_CITY_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={8}>
            <Form.Group controlId="input-street">
              <Form.Label>{SUBSIDIARY_MODAL_STREET_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="address-line1"
                onChange={onChangeStreet}
                value={street}
                placeholder={SUBSIDIARY_MODAL_STREET_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_STREET_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="input-housenumber">
              <Form.Label>{SUBSIDIARY_MODAL_HOUSENUMBER_TITLE}</Form.Label>
              <Form.Control
                as="input"
                autoComplete="address-line2"
                onChange={onChangeHousenumber}
                value={housenumber}
                placeholder={SUBSIDIARY_MODAL_HOUSENUMBER_PLACEHOLDER}
                required
              />
              <Form.Control.Feedback type="invalid">
                {SUBSIDIARY_MODAL_HOUSENUMBER_INVALID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col md={2}>
            <Form.Group controlId="color">
              <Form.Label>{SUBSIDIARY_MODAL_COLOR}</Form.Label>
              <Form.Control
                as="div"
                className="color-picker-input"
                style={{
                  background: rgbTorgba(colorSelectedFill, 1),
                }}
                onClick={showColorPicker}
              />
              {showPicker && (
                <ColorPicker
                  id={`${id}`}
                  color={rgbTorgbObject(colorSelectedFill, 1)}
                  accept={onChangeColor}
                  abort={showColorPicker}
                />
              )}
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group>
              <Form.Label>{SUBSIDIARY_MODAL_POI_TITLE}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable
                options={availablePOIs}
                value={availablePOIs.find(pPoi => pPoi.value === poi?.id)}
                placeholder={SUBSIDIARY_MODAL_POI_PLACEHOLDER}
                onChange={onChangePOI}
                theme={(theme: any) => selectPickerTheme(theme)}
                formatOptionLabel={poiIconSelectOption}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Button
            variant="primary"
            className="ci"
            onClick={onClickAddressSearch}
          >
            {addressLoading && (
              <Spinner as="span" animation="border" size="sm" />
            )}
            {SUBSIDIARY_MODAL_VERIFY_ADDRESS}
          </Button>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              {addresses && (
                <div className="mt-1">
                  <Form.Label>
                    {SUBSIDIARY_MODAL_VERIFY_ADDRESS_TITLE}
                  </Form.Label>
                  <ListGroup className="w-100">
                    {addresses.map(address => (
                      <ListGroup.Item
                        className="hover-item selectable"
                        key={address.coordinates.lat}
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => onClickAddressItem(event, address)}
                        active={address.selected}
                      >
                        {address.name}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              )}
              <Form.Control
                value={coordCheckValue}
                hidden
                onChange={() => {}}
                required
              />
              <Form.Control.Feedback type="invalid">
                {addresses
                  ? SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION
                  : SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
      </Col>
    </Row>
  );
};

export default ClientLocationData;
