import * as React from 'react';

import { Modal, Col, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { RGBColor } from 'react-color';
import { cloneDeep } from 'lodash';

import ClientLocationData from './ClientLocationData';
import ClientLocationOpeningHours from './ClientLocationOpeningHours';

import { rgbObjectTorgb, rgbTorgba } from '../../../util/featureStyle';
import {
  MODAL_TITLE_EDIT_SUBSIDIARY,
  MODAL_TITLE_ADD_SUBSIDIARY,
  BUTTON_TITLE_ACCEPT,
  BUTTON_TITLE_ABORT,
  SUBSIDIARY_MODAL_TAB_OPENING_HOURS,
  SUBSIDIARY_MODAL_TAB_DATA,
} from '../../../constants/labels';
import {
  SUBSIDIARY_MODAL_TAB_ID_DATA,
  SUBSIDIARY_MODAL_TAB_ID_OPENING_HOURS,
  SUBSIDIARY_MODAL_TABS,
  REQUEST_IDENTIFIER_GET_POIS,
} from '../../../constants/constants';
import { searchAddress, getPOIs } from '../../../util/api';

import {
  ClientLocation,
  OpeningHoursSend,
  ClientLocationSend,
  POI,
} from '../../../@types/Common.d';
import {
  ClientLocatoinModalProps,
  ClientLocatoinModalState,
  Address,
} from '../../../@types/Modal.d';

/**
 * Modal dialog to update and create subsidiaries
 */
export default class ClientLocationModal extends React.Component<
  ClientLocatoinModalProps,
  ClientLocatoinModalState
> {
  openingHoursRef = React.createRef<ClientLocationOpeningHours>();

  constructor(props: ClientLocatoinModalProps) {
    super(props);

    this.state = {
      id: -1,
      name: '',
      addressName: '',
      number: '',
      postcode: '',
      city: '',
      housenumber: '',
      street: '',
      colorSelectedFill: 'rgba(0, 0, 150, 1.0)',
      coordinates: { lat: -1, lon: -1 },
      showPicker: false,
      addressLoading: false,
      validated: false,
      pois: [],
    };

    this.hideModal = this.hideModal.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onChangeHousenumber = this.onChangeHousenumber.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAddressName = this.onChangeAddressName.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangePostCode = this.onChangePostCode.bind(this);
    this.onChangeStreet = this.onChangeStreet.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangePOI = this.onChangePOI.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickAddressSearch = this.onClickAddressSearch.bind(this);
    this.onClickAddressItem = this.onClickAddressItem.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
  }

  componentDidMount(): void {
    this.getClientPOIs();
  }

  componentDidUpdate(
    prevProps: ClientLocatoinModalProps,
    prevState: ClientLocatoinModalState
  ): void {
    const { clientLocation } = this.state;

    if (clientLocation?.id !== prevState.clientLocation?.id)
      this.getClientPOIs();
  }

  /**
   * Click action to submit create a new or update a subsidiary
   *
   * @param event
   */
  onClickSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState(
      {
        validated: true,
      },
      () => {
        if (formValid) {
          const {
            addressName,
            name,
            number,
            poi,
            phone,
            email,
            postcode,
            city,
            housenumber,
            street,
            colorSelectedFill,
            coordinates,
            clientLocation,
          } = this.state;
          let openingHours = [] as OpeningHoursSend[];

          const { current } = this.openingHoursRef;

          if (current !== null) openingHours = current.getOpeningHours();

          const { updateSusidiary, newSubsidiary } = this.props;

          const subsidiary = {
            addressName,
            city,
            colorSelectedFill: rgbTorgba(colorSelectedFill, 0.2),
            email,
            housenumber,
            id: clientLocation?.id ?? -1,
            lat: coordinates.lat,
            lon: coordinates.lon,
            name,
            number,
            openingHours,
            phone,
            planable: true,
            poi,
            postcode,
            street,
          } as ClientLocationSend;

          if (clientLocation) updateSusidiary(subsidiary);
          else newSubsidiary(subsidiary);

          this.hideModal();
        }
      }
    );
  }

  /**
   * Click action to search for coordinates for the provided address or vice versa
   *
   * @param event
   */
  async onClickAddressSearch(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();

    this.setState({ addressLoading: true });
    const { postcode, city, housenumber, street } = this.state;
    const resultAddresses = (await searchAddress(
      `${postcode} ${city} ${street} ${housenumber}`
    )) as Address[];

    this.setState({ addresses: resultAddresses, addressLoading: false });
  }

  /**
   * Click action to select one of the received subsidiaries
   *
   * @param event
   * @param address
   */
  onClickAddressItem(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    address: Address
  ): void {
    event.stopPropagation();

    const { addresses } = this.state;

    if (!addresses) return;

    addresses.forEach(aAddress => {
      if (address.name === aAddress.name) aAddress.selected = true;
      else aAddress.selected = false;
    });

    this.setState({
      addresses: [...addresses],
      coordinates: address.coordinates,
    });
  }

  /**
   * Click action to abort the creation or update of the subsidiary
   *
   * @param event
   */
  onClickAbort(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();

    this.hideModal();
  }

  /**
   * Change action for the subsidiaries name
   *
   * @param event
   */
  onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();

    this.setState({
      name: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries address name
   *
   * @param event
   */
  onChangeAddressName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();

    this.setState({
      addressName: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries company number
   *
   * @param event
   */
  onChangeNumber(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      number: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries postcode
   *
   * @param event
   */
  onChangePostCode(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      postcode: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries city
   *
   * @param event
   */
  onChangeCity(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      city: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries housenumber
   *
   * @param event
   */
  onChangeHousenumber(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      housenumber: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries street
   *
   * @param event
   */
  onChangeStreet(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      street: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries contact email
   *
   * @param event
   */
  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      email: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries contact phone
   *
   * @param event
   */
  onChangePhone(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      phone: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries selection color
   *
   * @param event
   */
  onChangeColor(color: RGBColor): void {
    this.setState({ colorSelectedFill: rgbObjectTorgb(color, 0.2) });
  }

  /**
   * Change poi of the subsidiaries for display on map
   *
   * @param selectedClietPOIIcon
   */
  onChangePOI(selectedPOI: any): void {
    if (selectedPOI && selectedPOI !== null)
      this.setState({ poi: selectedPOI.data });
  }

  /**
   * Receive all available POIs for client location display
   * on the map
   */
  async getClientPOIs(): Promise<void> {
    const { enableLoadingOverlay } = this.props;

    const pois = (await getPOIs()) as POI[];

    if (!Number.isNaN(+pois)) {
      enableLoadingOverlay(false, REQUEST_IDENTIFIER_GET_POIS);
      return;
    }

    this.setState({ pois });
  }

  /**
   * Set the subsidary to be edited. If the subsidiary which is provided is undefined
   * this will be a modal dialog for a new subsdiary.
   *
   * @param clientLocation
   */
  setClientLocation(clientLocation: ClientLocation): void {
    const pClientLocation = cloneDeep(clientLocation);
    const {
      id,
      addressName,
      colorSelectedFill,
      email,
      name,
      number,
      poi,
      postcode,
      city,
      housenumber,
      phone,
      street,
      lat,
      lon,
    } = pClientLocation;

    const coordinates = { lat, lon };

    this.setState({
      id,
      addressName,
      clientLocation: pClientLocation,
      colorSelectedFill,
      email,
      name,
      number,
      poi,
      postcode,
      city,
      housenumber,
      street,
      phone,
      coordinates,
      addresses: undefined,
    });
  }

  /**
   * Method to show a color picker to choose a color
   * for the subsidiary.
   */
  showColorPicker(): void {
    const { showPicker } = this.state;
    this.setState({ showPicker: !showPicker });
  }

  /**
   * Method to hide the modal dialog and clear the state
   * of the prevoiusly submited data.
   */
  hideModal(): void {
    const { showModal } = this.props;

    this.setState(
      {
        id: -1,
        name: '',
        addressName: '',
        number: '',
        postcode: '',
        city: '',
        housenumber: '',
        street: '',
        colorSelectedFill: 'rgba(0, 0, 150, 1.0)',
        coordinates: { lat: -1, lon: -1 },
        pois: [],
        poi: undefined,
        showPicker: false,
        addressLoading: false,
        validated: false,
        addresses: undefined,
        clientLocation: undefined,
        email: undefined,
        phone: undefined,
      },
      () => showModal(false)
    );
  }

  render(): JSX.Element {
    const { container, show } = this.props;
    const {
      addresses,
      addressLoading,
      addressName,
      city,
      clientLocation,
      coordinates,
      colorSelectedFill,
      email,
      housenumber,
      id,
      name,
      number,
      phone,
      poi,
      pois,
      postcode,
      showPicker,
      street,
      validated,
    } = this.state;

    const coordCheckValue = coordinates.lat >= 0 ? `${coordinates.lat}` : '';

    return (
      <Modal
        show={show}
        onHide={this.hideModal}
        container={container.current}
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {clientLocation
              ? MODAL_TITLE_EDIT_SUBSIDIARY
              : MODAL_TITLE_ADD_SUBSIDIARY}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            id="client-location-modal-form"
            className="client-location-modal-form"
            onSubmit={this.onClickSubmit}
            noValidate
            validated={validated}
          >
            <Tabs
              defaultActiveKey={SUBSIDIARY_MODAL_TAB_ID_DATA}
              id={SUBSIDIARY_MODAL_TABS}
            >
              <Tab
                eventKey={SUBSIDIARY_MODAL_TAB_ID_DATA}
                title={SUBSIDIARY_MODAL_TAB_DATA}
              >
                <ClientLocationData
                  addresses={addresses}
                  addressLoading={addressLoading}
                  addressName={addressName}
                  city={city}
                  colorSelectedFill={colorSelectedFill}
                  email={email}
                  housenumber={housenumber}
                  id={id}
                  name={name}
                  number={number}
                  poi={poi}
                  pois={pois}
                  postcode={postcode}
                  phone={phone}
                  showPicker={showPicker}
                  street={street}
                  coordCheckValue={coordCheckValue}
                  onChangeName={this.onChangeName}
                  onChangeNumber={this.onChangeNumber}
                  onChangeAddressName={this.onChangeAddressName}
                  onChangePostCode={this.onChangePostCode}
                  onChangeCity={this.onChangeCity}
                  onChangeStreet={this.onChangeStreet}
                  onChangeHousenumber={this.onChangeHousenumber}
                  onChangeColor={this.onChangeColor}
                  onChangePhone={this.onChangePhone}
                  onChangeEmail={this.onChangeEmail}
                  onChangePOI={this.onChangePOI}
                  onClickAddressSearch={this.onClickAddressSearch}
                  onClickAddressItem={this.onClickAddressItem}
                  showColorPicker={this.showColorPicker}
                />
              </Tab>
              <Tab
                eventKey={SUBSIDIARY_MODAL_TAB_ID_OPENING_HOURS}
                title={SUBSIDIARY_MODAL_TAB_OPENING_HOURS}
              >
                <ClientLocationOpeningHours
                  ref={this.openingHoursRef}
                  clientLocation={clientLocation}
                />
              </Tab>
            </Tabs>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Col className="p-0">
            <Button
              type="submit"
              variant="primary"
              className="ci"
              form="client-location-modal-form"
            >
              {BUTTON_TITLE_ACCEPT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              variant="primary"
              className="ci"
              onClick={this.onClickAbort}
            >
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
