import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

import { LayerTypeDisplayMatcher } from '../../constants/constants';
import { LAYER_TYPE_APPLY_TO_ALL } from '../../constants/labels';

import {
  LayerTypeSelectionProps,
  LayerTypeSelectionItemProps,
} from '../../@types/Map.d';

const LayerTypeSelection: React.FC<LayerTypeSelectionProps> = (
  props: LayerTypeSelectionProps
) => {
  const {
    layerTypes,
    selectedLayerType,
    applyToAll,
    closeSelection,
    setLayerType,
    changeApplyToAll,
  } = props;

  const onClickClose = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    closeSelection();
  };

  const onKeyDownClose = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.key !== 'Enter') return;

    closeSelection();
  };

  const onChangeApplyToAll = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.stopPropagation();

    changeApplyToAll(event.currentTarget.checked);
  };

  return (
    <div className="layer-type-selection-container">
      <div className="layer-type-selection-items">
        {layerTypes
          .filter(layerType => LayerTypeDisplayMatcher[layerType])
          .map(layerType => (
            <LayerTypeSelectionItem
              key={layerType}
              selected={selectedLayerType === layerType}
              layerType={layerType}
              setLayerType={setLayerType}
            />
          ))}
      </div>
      {selectedLayerType && (
        <>
          <div
            className="close-button"
            role="button"
            tabIndex={-1}
            onClick={onClickClose}
            onKeyDown={onKeyDownClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="convert-areas-container">
            <Form.Group>
              <Form.Check
                id="append-to-list-check"
                custom
                className="mt-2"
                type="checkbox"
                onChange={onChangeApplyToAll}
                checked={applyToAll}
                label={LAYER_TYPE_APPLY_TO_ALL}
              />
            </Form.Group>
          </div>
        </>
      )}
    </div>
  );
};

export default LayerTypeSelection;

const LayerTypeSelectionItem: React.FC<LayerTypeSelectionItemProps> = (
  props: LayerTypeSelectionItemProps
) => {
  const { layerType, selected, setLayerType } = props;

  const { title, image } = LayerTypeDisplayMatcher[layerType];

  const onClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    setLayerType(layerType);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.key !== 'Enter') return;

    setLayerType(layerType);
  };

  return (
    <div className="layer-type-item">
      <div
        className="layer-type-item-content"
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={-1}
      >
        <img src={image} alt={title} />
        <div className="title">{title}</div>
        <div className="selected">
          {selected && <FontAwesomeIcon icon={faCheck} />}
        </div>
      </div>
    </div>
  );
};
