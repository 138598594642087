import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import DistributionTemplateListItem from './DistributionTemplteListItem';
import ListAddItem from '../common/ListAddItem';

import { sortDistributionTemplates } from '../../util/sortUtil';

import {
  MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE,
  DISTRIBUTION_TEMPLATE_LIST_EMPTY,
} from '../../constants/labels';

import { DistributionTemplateListPorps } from '../../@types/DistributionTemplateList.d';

/**
 * Component to display a list of available distribution templates
 *
 * @param props
 */
const DistributionTemplateList: React.FC<DistributionTemplateListPorps> = (
  props: DistributionTemplateListPorps
) => {
  const {
    distributionTemplates,
    planningRestriction,
    deleteDistributionTemplate,
    applyDistributionTemplate,
    showNewDistributionTemplateModal,
    updateDistributionTemplate,
  } = props;

  return (
    <div className="list-container">
      <ListGroup className="flex-list">
        {distributionTemplates.length > 0 ? (
          sortDistributionTemplates(
            distributionTemplates
          ).map(distributionTemplate => (
            <DistributionTemplateListItem
              key={distributionTemplate.id}
              distributionTemplate={distributionTemplate}
              planningRestriction={planningRestriction}
              deleteDistributionTemplate={deleteDistributionTemplate}
              applyDistributionTemplate={applyDistributionTemplate}
              updateDistributionTemplate={updateDistributionTemplate}
            />
          ))
        ) : (
          <ListGroup.Item>{DISTRIBUTION_TEMPLATE_LIST_EMPTY}</ListGroup.Item>
        )}
      </ListGroup>
      {planningRestriction === 'NONE' && (
        <ListAddItem
          showNewModal={showNewDistributionTemplateModal}
          title={MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE}
        />
      )}
    </div>
  );
};

export default DistributionTemplateList;
