import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import './styles/index.scss';
import 'ol/ol.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import {
  PARAM_WEEKPART,
  PARAM_CLIENT_ID,
  PARAM_PARENTORIGIN,
  PARAM_SUBSIDIARYMODE,
  PARAM_EMAIL,
  PARAM_AREAKEYS,
  PARAM_DISTRIBUTION_WEEK,
  PARAM_DISTRIBUTION_YEAR,
} from './constants/network';

import { WEEKPART_WEEKEND } from './constants/constants';

import { Weekpart } from './@types/Common.d';

const location = window.location.toString();
const params = location.split('?');
const { subsidiaryMode, defaultWeekpart } = config.general;

// Variables for rhe configuration options provided by the iFrames parameters
let sSubsidiaryMode: boolean = subsidiaryMode;
let weekpart: Weekpart = (defaultWeekpart ?? WEEKPART_WEEKEND) as Weekpart;
let userMail;
let clientUUID: string | undefined;
let parentOrigin: string = '';
let initAreas: string = '';
let initDistributionWeek = moment().isoWeek();
let initDistributionYear = moment().year();

if (params.length > 1) {
  const values = params[1].split('&');

  // Iterate through the url parameters and set the according
  // configuration variables
  values.forEach(param => {
    const paramPair = param.split('=', 2);
    const [key, value] = paramPair;

    switch (key.toLocaleLowerCase()) {
      case PARAM_WEEKPART:
        weekpart = value as Weekpart;
        break;
      case PARAM_CLIENT_ID:
        clientUUID = value;
        break;
      case PARAM_PARENTORIGIN:
        parentOrigin = value;
        break;
      case PARAM_SUBSIDIARYMODE:
        sSubsidiaryMode = value === '1';
        break;
      case PARAM_EMAIL:
        userMail = value;
        break;
      case PARAM_AREAKEYS:
        initAreas = value;
        break;
      case PARAM_DISTRIBUTION_WEEK:
        initDistributionWeek = parseInt(value, 0);
        break;
      case PARAM_DISTRIBUTION_YEAR:
        initDistributionYear = parseInt(value, 0);
        break;
      default:
    }
  });
}

ReactDOM.render(
  <App
    subsidiaryMode={sSubsidiaryMode}
    clientUUID={clientUUID}
    initWeekpart={weekpart}
    parentOrigin={parentOrigin}
    userMail={userMail}
    initAreas={initAreas}
    initDistributionWeek={initDistributionWeek}
    initDistributionYear={initDistributionYear}
  />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
